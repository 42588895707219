<template>
  <div class="leave">
    <title-bar
      title="Niet gevonden"
      :left="{ text: 'Terug', path: '/' }"
    />
  </div>
</template>

<script>
// import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import leaveTypes from '../leaveTypes';
import TitleBar from '../components/TitleBar.vue';

export default {
  name: 'leave',

  components: {
    TitleBar,
  },

  data() {
    return {
      leaveTypes,
    };
  },

  computed: {
    ...mapGetters([
      //
    ]),
  },

  methods: {
    ...mapActions([
      //
    ]),
  },

  mounted() {
    this.$router.push('/');
  },
};
</script>

<style>
  /* */
</style>
