<template>
  <div class="contact">
    <title-bar
      :left="{ path: '/contacts', text: 'Contacten', icon: 'chevron-left' }"
      :title="contact ? contact.name : 'Contact'"
    />

    <div class="content-section contact__content" v-if="contact">
      <div class="contact__head">
        <img
          class="contact__head-avatar"
          :src="contact.image ? contact.image : '/img/user-placeholder.jpg'"
          alt=""
        >

        <div class="contact__head-content">
          <div>
            <strong>{{ contact.name }}</strong>
          </div>

          <div v-if="contact.function">
            {{ contact.function }}
          </div>
        </div>
      </div>

      <div class="contact__item" v-if="contact.email">
        {{ contact.email }}

        <a class="contact__item-action" :href="`mailto:${contact.email}`">
          Mail
        </a>
      </div>

      <div class="contact__item" v-if="contact.phone && contact.phone !== '0'">
        {{ contact.phone }}

        <a class="contact__item-action" :href="`tel:${formattedPhoneNumber}`">
          Bel
        </a>
      </div>

      <div class="contact__item" v-if="contact.phone && contact.phone !== '0'">
        <a :href="`https://wa.me/${formattedWhatsAppNumber}`" target="_blank" rel="noopener">
          WhatsApp
        </a>
      </div>

      <div class="contact__item" v-if="contact.phone && contact.phone !== '0'">
        <a :href="`sms:${formattedPhoneNumber}`">
          SMS
        </a>
      </div>
    </div>
  </div>
</template>

<script>
// import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import TitleBar from '../../components/TitleBar.vue';
import FormatNumberForLink from '../../helpers/FormatNumberForLink';

export default {
  name: 'contact',

  components: {
    TitleBar,
  },

  data() {
    return {
    };
  },

  computed: {
    ...mapGetters([
      'contacts',
    ]),

    formattedPhoneNumber() {
      if (!this.contact) {
        return null;
      }

      return FormatNumberForLink(this.contact.phone);
    },

    formattedWhatsAppNumber() {
      if (!this.contact) {
        return null;
      }

      return FormatNumberForLink(this.contact.phone, true);
    },

    contact() {
      const { id } = this.$route.params;

      const filteredContacts = this.contacts ? this.contacts.filter((contact) => (
        contact.id === parseInt(id, 10)
      )) : [];

      return filteredContacts.length ? filteredContacts[0] : null;
    },
  },

  methods: {
    ...mapActions([
      //
    ]),
  },
};
</script>

<style scoped>
.contact {
  height: 100%;
}

.contact__content {
  padding: .875rem;
}

.contact__head {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.contact__head-avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  flex: 0 0 auto;
  object-fit: cover;
  margin-right: .75rem;
}

.contact__item {
  padding: .875rem 1rem;
  margin-left: -.875rem;
  margin-right: -.875rem;
  border-top: 1px solid #ddd;
  display: flex;
}

.contact__item:last-child {
  border-bottom: 1px solid #ddd;
}

.contact__item-action {
  margin-left: auto;
}

.contact a {
  color: #333;
}
</style>
