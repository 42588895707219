import ApiRequest from '../helpers/ApiRequest';

const KilometerRegistrationStore = {
  state: {
    registrations: null,
  },

  getters: {
    registrations(state) {
      return state.registrations;
    },

    getRegistrationById: (state, getters) => (id) => {
      if (!getters.registrations) {
        return null;
      }

      const registrations = getters.registrations.filter((registration) => (
        registration.id === parseInt(id, 10)
      ));

      return registrations.length ? registrations[0] : null;
    },
  },

  mutations: {
    setRegistrations(state, registrations) {
      state.registrations = registrations;
    },
  },

  actions: {
    async postDistanceCalculation({ rootState }, formData) {
      return ApiRequest.authorizedPost('/kilometer-registration/distance', formData, rootState.user.currentJWT);
    },

    async postKilometerRegistration({ rootState }, formData) {
      return ApiRequest.authorizedPost('/km-registration', formData, rootState.user.currentJWT);
    },

    async putKilometerRegistration({ rootState }, [id, formData]) {
      formData.append('_method', 'PUT');
      return ApiRequest.authorizedPost(`/km-registration/${id}`, formData, rootState.user.currentJWT);
    },

    async fetchKilometerRegistrations({ rootState, commit }) {
      const res = await ApiRequest.authorizedGet('/kilometer-registration/all', rootState.user.currentJWT);

      commit('setRegistrations', res.data);
    },
  },
};

export default KilometerRegistrationStore;
