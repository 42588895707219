<template>
  <div class="dashboard">
    <title-bar
      :left="{ path: '/login', text: 'Login' }"
      title="Wachtwoord vergeten"
    />

    <div class="content-section">
      <form
        action="#"
        method="POST"
        @submit="onSubmit"
        ref="passwordForgotForm"
      >
        <div class="alert alert--danger" v-if="forgotState === 'error'">
          We konden het wachtwoord niet herstellen, mogelijk bestaat
          dit e-mailadres niet in ons systeem.
        </div>

        <div class="alert alert--success" v-if="forgotState === 'success'">
          We hebben het wachtwoord hersteld. Binnen enkele ogenblikken ontvang
          je een e-maildres voor het instellen van een nieuw wachtwoord.
        </div>

        <div class="form-group">
          <label for="email">E-mailadres</label>
          <input
            class="form-control"
            type="email"
            name="email"
            id="email"
            value=""
          >
        </div>

        <div class="form-group">
          <button
            class="btn btn--primary w-100"
            type="submit"
            :disabled="forgotState === 'loading'"
          >
            {{ forgotState === 'loading' ? 'Een moment geduld...' : 'Wachtwoord herstellen' }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapActions } from 'vuex';
import TitleBar from '../../components/TitleBar.vue';

export default {
  name: 'login',

  components: {
    TitleBar,
  },

  data() {
    return {
      forgotState: 'idle',
    };
  },

  computed: {
    //
  },

  methods: {
    ...mapActions([
      'fetchForgotPassword',
    ]),

    onSubmit(e) {
      e.preventDefault();

      const { passwordForgotForm } = this.$refs;
      const data = new FormData(passwordForgotForm);

      Vue.set(this, 'forgotState', 'loading');

      this.fetchForgotPassword({
        email: data.get('email'),
      })
        .then(() => {
          Vue.set(this, 'forgotState', 'success');

          // Reset email field
          passwordForgotForm.querySelector('[name="email"]').value = '';
        })
        .catch(() => {
          Vue.set(this, 'forgotState', 'error');
        });
    },
  },
};
</script>
