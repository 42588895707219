import ApiRequest from '../helpers/ApiRequest';

const SettingsStore = {
  state: {
    introSeen: false,
  },

  getters: {
    hasSeenIntro(state) {
      return state.introSeen;
    },
  },

  mutations: {
    setIntroSeen(state) {
      state.introSeen = true;
    },
  },

  actions: {
    async postSettingsForm({ rootState }, values) {
      await ApiRequest.authorizedPost('/settings', values, rootState.user.currentJWT);
    },

    markIntroSeen({ commit }) {
      commit('setIntroSeen');
    },
  },
};

export default SettingsStore;
