import map from 'lodash/map';
import filter from 'lodash/filter';
import { parseISO } from 'date-fns';

/**
 * Get array of dates for a specific predicate
 *
 * @param {Array} dates
 * @param {String} key
 * @return {Array}
 */
function getGroup(dates, predicate) {
  const filtered = filter(dates, (item) => (
    !!item[predicate]
  ));

  return map(filtered, (planningItem) => {
    if (typeof planningItem.date === 'string') {
      if (typeof planningItem.end_date === 'string' && planningItem.date !== planningItem.end_date) {
        return [parseISO(planningItem.date), parseISO(planningItem.end_date)];
      }

      return parseISO(planningItem.date);
    }

    return parseISO(planningItem.date.date);
  });
}

function flattenArray(array) {
  const newArray = [];

  array.forEach((item) => {
    if (Array.isArray(item)) {
      newArray.push(...item);
    } else {
      newArray.push(item);
    }
  });

  return newArray;
}

/**
 * Return dates in a format that can be fed to VCalendar
 *
 * @param {Array} dates
 * @return {Array}
 */
export default (dates) => {
  const scheduled = {
    dot: 'blue',
    dates: flattenArray(getGroup(dates, 'is_scheduled')),
  };

  const shifts = {
    dot: 'red',
    dates: flattenArray(getGroup(dates, 'is_shift')),
  };

  return [shifts, scheduled];
};
