<template>
  <div class="greeter">
    <h1 class="greeter__heading">{{ greeting }}{{ user ? ` ${user.fullname}` : '' }}</h1>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import getGreeting from '../helpers/GetGreeting';

export default {
  name: 'Greeter',
  props: {
    msg: String,
  },
  computed: {
    ...mapGetters([
      'user',
    ]),
    greeting() {
      return getGreeting();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.greeter {
  background-color: var(--primary-color-shade);
  color: #fff;
  padding: 1rem .875rem;
}

.greeter__heading {
  margin: 0;
  font-size: 1.25rem;
}
</style>
