<template>
  <div class="km-registration h-100">
    <title-bar
      :left="{ text: 'Alles', path: '/kilometer-registrations' }"
      title="Kilometer registratie"
    />

    <form class="content-section" ref="form" action="" v-if="activeVehicle">
      <div class="form-group">
        <label for="start_mileage">Begin kilometerstand</label>
        <input
          class="form-control"
          type="number"
          name="start_mileage"
          id="start_mileage"
          :value="activeVehicle.last_mileage"
          readonly
        >
      </div>

      <div class="form-group">
        <label for="from">Van adres</label>
        <input
          class="form-control"
          type="text"
          name="from"
          id="from"
          :value="activeVehicle.last_location"
          required
          readonly
        >
      </div>

      <div class="form-group">
        <label for="to">Naar adres</label>
        <input class="form-control" type="text" name="to" id="to" @keyup="onAddressChange" required>
      </div>

      <div class="form-group">
        <label for="date">Datum</label>
        <input class="form-control" type="date" name="date" id="date" :value="defaultDate" required>
      </div>

      <div class="form-group">
        <label for="type">Type</label>
        <select class="select-control" name="type" id="type">
          <option value="business">Zakelijk</option>
          <option value="private">Privé</option>
        </select>
      </div>

      <div class="form-group" v-if="distanceFormState === 'success'">
        <label>Afstand</label>
        <strong>{{ Math.round(distance) }} km</strong>
      </div>

      <div class="form-group" v-if="distanceFormState === 'success'">
        <label for="end_mileage">Eind kilometerstand</label>
        <input
          class="form-control"
          type="number"
          name="end_mileage"
          id="end_mileage"
          :value="activeVehicle.last_mileage + distance"
        >
      </div>

      <div class="alert alert--danger" v-if="distanceFormState === 'error'">
        Er ging iets mis bij het ophalen van de afstand. Probeer een meer omschrijvende
        'naar adres' en probeer het opnieuw.
      </div>

      <div class="alert alert--danger" v-if="formErrors && formErrors.length">
        <ul>
          <li v-for="formError in formErrors" :key="formError">{{ formError }}</li>
        </ul>
      </div>

      <div class="alert alert--danger" v-if="formState === 'error'">
        Er ging iets mis bij het opslaan van je kilometerregistratie. Controleer je gegevens
        en probeer het opnieuw.
      </div>

      <div class="form-group">
        <button
          type="submit"
          class="btn btn--primary w-100"
          :disabled="distanceFormState === 'loading' || formState === 'loading'"
        >
          <div v-if="distanceFormState === 'success'">
            {{ formState === 'loading' ? 'Een moment...' : 'Kilometerregistratie voltooien' }}
          </div>
          <div v-else>
            {{ distanceFormState === 'loading' ? 'Een moment...' : 'Kilometers berekenen' }}
          </div>
        </button>
      </div>
    </form>
    <div class="p-3" v-else>
      Je hebt geen actief voertuig.
    </div>
  </div>
</template>

<script>
/* global gtag */

import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import porta from 'porta';
import nl from 'porta/src/i18n/nl';
import { format } from 'date-fns';
import leaveTypes from '../../leaveTypes';
import TitleBar from '../../components/TitleBar.vue';

export default {
  name: 'kilometer-registration',

  components: {
    TitleBar,
  },

  data() {
    return {
      leaveTypes,
      distanceFormState: 'idle',
      formState: 'idle',
      distance: 0,
      formErrors: [],
    };
  },

  computed: {
    ...mapGetters([
      'activeVehicle',
    ]),

    defaultDate() {
      return format(new Date(), 'yyyy-MM-dd');
    },
  },

  methods: {
    ...mapActions([
      'postDistanceCalculation',
      'postKilometerRegistration',
      'fetchUser',
      'fetchKilometerRegistrations',
    ]),

    onSubmit() {
      const formData = new FormData(this.$refs.form);

      this.formErrors = [];

      // The user already calculated the distance, so make the
      // registration permanent
      if (this.distanceFormState === 'success') {
        if (!this.validateForm(formData)) {
          return;
        }

        this.postRegistration(formData);
        return;
      }

      // Calculate the distance in KM to the new address
      this.calculateDistance(formData);
    },

    calculateDistance(formData) {
      Vue.set(this, 'distanceFormState', 'loading');

      this.postDistanceCalculation(formData)
        .then(({ data }) => {
          const { distance } = data;

          Vue.set(this, 'distance', Math.round(distance / 1000));
          Vue.set(this, 'distanceFormState', 'success');
        })
        .catch(() => {
          Vue.set(this, 'distanceFormState', 'error');
        });
    },

    postRegistration(formData) {
      Vue.set(this, 'formState', 'loading');

      this.postKilometerRegistration(formData)
        .then(() => {
          Vue.set(this, 'formState', 'success');
          this.fetchUser();
          this.fetchKilometerRegistrations();
          this.resetForm();
          this.$toasted.show('Kilometer registratie is verzonden', { type: 'success' }).goAway(2000);

          if (typeof gtag !== 'undefined') {
            gtag('event', 'submit', {
              event_category: 'KM Registration',
              event_label: '',
            });
          }
        })
        .catch(() => {
          Vue.set(this, 'formState', 'error');
        });
    },

    resetForm() {
      Vue.set(this, 'formState', 'idle');
      Vue.set(this, 'distanceFormState', 'idle');

      this.$refs.form.querySelector('[name="to"]').value = '';
    },

    onAddressChange() {
      Vue.set(this, 'distanceFormState', 'idle');
    },

    validateForm(formData) {
      const startMileage = formData.get('start_mileage');
      const endMileage = formData.get('end_mileage');

      if (!startMileage || !endMileage) {
        this.formErrors.push('De begin- of eindkilometerstand mist');
        return false;
      }

      const startMileageInt = parseInt(startMileage, 10);
      const endMileageInt = parseInt(endMileage, 10);

      if (typeof Number.isNaN === 'function' && (Number.isNaN(startMileageInt) || Number.isNaN(endMileageInt))) {
        this.formErrors.push('De begin- of eindkilometerstand is geen nummer');
        return false;
      }

      if (endMileageInt <= startMileageInt) {
        this.formErrors.push('De eindkilometerstand moet groter zijn dan de beginstand');
        return false;
      }

      // This is enormously high, but it will keep completely wrong data from
      // being entered.
      const maxKilometerDistance = 3000;

      if ((endMileageInt - startMileageInt) > maxKilometerDistance) {
        this.formErrors.push('De ingevoerde kilometerstand is meer dan 3000 kilomter, controleer de standen');
        return false;
      }

      return true;
    },
  },

  mounted() {
    if (this.$refs.form) {
      porta({
        target: this.$refs.form,
        onValid: () => {
          this.onSubmit();

          return false;
        },
        messages: nl,
      });
    }
  },
};
</script>

<style scoped>
</style>

<style>
.porta-error-message {
  color: #d00;
  padding-top: 5px;
}
</style>
