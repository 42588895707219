import { parseISO } from 'date-fns';
import format from './format';

function formatDate(dateString) {
  return format(parseISO(dateString), 'EEEE dd-MM-yyyy HH:mm');
}

export default (items) => items.map((item) => {
  const clonedItem = { ...item };
  clonedItem.startFormatted = formatDate(clonedItem.start);
  clonedItem.endFormatted = formatDate(clonedItem.end);

  clonedItem.is_concept = typeof clonedItem.status !== 'undefined' && clonedItem.status === 'concept'
    ? true
    : (typeof clonedItem.project_status !== 'undefined' && clonedItem.project_status === 'concept');

  return clonedItem;
});
