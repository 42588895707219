<template>
  <div class="title-bar">
    <router-link class="title-bar__left" v-if="left && left.path" :to="left.path">
      <font-awesome-icon
        v-if="left.icon"
        :icon="left.icon"
        class="title-bar__left-icon"
      />
      {{ left.text }}
    </router-link>

    <button type="button" class="title-bar__left" v-if="leftbutton" @click="leftbutton.action">
      <font-awesome-icon
        v-if="leftbutton.icon"
        :icon="leftbutton.icon"
        class="title-bar__left-icon"
      />
      {{ leftbutton.text }}
    </button>

    <span class="title-bar__title">
      {{ title }}
    </span>

    <router-link class="title-bar__right" v-if="right && right.path" :to="right.path">
      {{ right.text }}
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Titel',
    },

    leftbutton: {
      type: Object,
      default: null,
    },

    left: {
      type: Object,
      default: null,
    },

    right: {
      type: Object,
      default: null,
    },
  },

  computed: {
    //
  },
};
</script>

<style scoped>
.title-bar {
  height: 48px;
  background-color: var(--title-bar-background-color);
  color: var(--title-bar-color);
  display: flex;
  align-items: center;
  user-select: none;
  position: relative;
}

.title-bar__left,
.title-bar__right {
  position: relative;
  z-index: 2;
  color: #fff;
  padding: 0 1rem;
  background-color: transparent;
  border: 0;
  font-size: .875rem;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.title-bar__left:focus,
.title-bar__right:focus {
  outline: none;
}

.title-bar__right {
  margin-left: auto;
}

.title-bar__left-icon {
  margin-right: .375rem;
}

.title-bar__title {
  text-align: center;
  font-weight: 700;
  font-size: 1rem;
  position: absolute;
  top: 0;
  left: 0;
  line-height: 48px;
  width: 100%;
}
</style>
