<template>
  <div class="time-entries h-100">
    <title-bar
      :leftbutton="{ text: 'Ververs', action: refreshEntries, icon: 'sync' }"
      title="Goedkeuren"
    />

    <time-entry-tab-bar />

    <div
      :class="{
        'content-section': true,
        'is-approving': timeEntryApprovalLoadingState === 'loading',
      }"
    >
      <ul class="time-entries__list" v-if="toReviewItems.length">
        <time-entry-approval-row
          v-for="timesheet in toReviewItems"
          :key="timesheet.id"
          :timesheet="timesheet"
          @approved="onTimesheetApproved"
          @disapproved="onTimesheetDisapproved"
        />
      </ul>

      <div class="time-entries__empty-state" v-else>
        Op het moment zijn er geen tijd registraties om te controleren.
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import parseISO from 'date-fns/parseISO';
import TitleBar from '../../components/TitleBar.vue';
import TimeEntryTabBar from './TimeEntryTabBar.vue';
import format from '../../helpers/format';
import formatTime from '../../helpers/formatTime';
import TimeEntryApprovalRow from './TimeEntryApprovalRow.vue';

export default {
  name: 'time-entry-approval',

  components: {
    TimeEntryApprovalRow,
    TimeEntryTabBar,
    TitleBar,
  },

  data() {
    return {
      formState: 'idle',
    };
  },

  computed: {
    ...mapGetters([
      'timesheetShifts',
      'toReview',
      'timeEntryApprovalLoadingState',
    ]),

    toReviewItems() {
      return this.toReview.map((timesheet) => {
        const clone = { ...timesheet };

        clone.formattedStartTime = formatTime(clone.start_time);
        clone.formattedEndTime = formatTime(clone.end_time);

        clone.formattedStartDate = format(parseISO(clone.start_date), 'EEEE d-MM-yyyy');
        clone.formattedEndDate = format(parseISO(clone.end_date), 'EEEE d-MM-yyyy');

        return clone;
      });
    },
  },

  methods: {
    ...mapActions([
      'fetchTimeEntries',
      'approveTimeEntry',
      'fetchUser',
    ]),

    refreshEntries() {
      this.fetchTimeEntries();
      this.fetchUser();
    },

    onTimesheetApproved() {
      this.fetchTimeEntries();
    },

    onTimesheetDisapproved() {
      this.fetchTimeEntries();
    },
  },

  watch: {
    $route: 'refreshEntries',
  },

  mounted() {
    this.refreshEntries();
  },
};
</script>

<style scoped>
.time-entries .content-section {
  max-height: calc(100% - 48px - 49px);
  transition: opacity .1s ease-out;
}

.time-entries .content-section.is-approving {
  opacity: .5;
  pointer-events: none;
}

.time-entries__list {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.time-entries__empty-state {
  padding: 3rem 1rem;
  text-align: center;
  font-size: 1.125rem;
  max-width: 350px;
  margin: 0 auto;
}
</style>
