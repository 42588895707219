<template>
  <div class="">
    <title-bar
      title="Nieuws"
      :right="{ path: '/safety-contact', text: 'Melding doen' }"
    />

    <virtual-list class="news__list" :size="100" :remain="15">
      <router-link
        v-for="newsItem in formattedNews"
        :key="newsItem.id"
        :to="`/news/${newsItem.id}`"
        class="news-item list-item"
      >
        <div class="news-item__image">
          <img
            :src="newsItem.image ? newsItem.image : '/img/placeholder.jpg'"
            alt=""
            width="82"
            height="82"
          >
        </div>

        <div class="news-item__content">
          <time class="news-item__date" :datetime="newsItem.attributeDate">
            {{ newsItem.formattedDate }}
          </time>

          <strong class="news-item__title">
            {{ newsItem.title }}
          </strong>

          <div class="news-item__description">
            {{ newsItem.description }}
          </div>
        </div>
      </router-link>
    </virtual-list>

    <div class="news__categories">
      <button
        :class="`news__category ${newsCategory === category.value ? 'is-active' : ''}`"
        type="button"
        v-for="category in categories"
        :key="category.title"
        @click="(e) => { onCategoryClick(e, category) }"
      >
        {{ category.title }}
      </button>
    </div>
  </div>
</template>

<script>
// import Vue from 'vue';
import virtualList from 'vue-virtual-scroll-list';
import { mapActions, mapGetters } from 'vuex';
import parseISO from 'date-fns/parseISO';
import TitleBar from '../../components/TitleBar.vue';
import format from '../../helpers/format';

export default {
  name: 'news',

  components: {
    virtualList,
    TitleBar,
  },

  data() {
    return {
      categories: [
        { title: 'Alles', value: null },
        { title: 'Nieuws', value: 'news' },
        { title: 'Introducties', value: 'introductions' },
        { title: 'Meldingen', value: 'issues' },
        { title: 'Gedragsregels', value: 'rulesofconduct' },
      ],
    };
  },

  mounted() {
    this.fetchNews();
  },

  computed: {
    ...mapGetters([
      'newsFiltered',
      'newsCategory',
    ]),

    formattedNews() {
      if (!this.newsFiltered) {
        return null;
      }

      return this.newsFiltered.map((newsItem) => {
        const formattedNewsItem = newsItem;
        formattedNewsItem.formattedDate = format(parseISO(newsItem.date), 'd MMMM yyyy');
        formattedNewsItem.attributeDate = format(parseISO(newsItem.date), 'yyyy-MM-dd HH:mm');

        return newsItem;
      });
    },
  },

  methods: {
    ...mapActions([
      'fetchNews',
      'setNewsCategory',
    ]),

    onCategoryClick(event, category) {
      this.setNewsCategory(category.value);
    },
  },
};
</script>

<style scoped>
.news-item {
  height: 100px;
  font-size: .875rem;
  line-height: 1.5;
}

.news-item__image {
  position: relative;
  width: 82px;
  height: 82px;
  overflow: hidden;
  margin: 0 .75rem 0 -.5rem;
  flex: 0 0 auto;
}

.news-item__image img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.news-item__content {
  flex: 0 0 auto;
  max-width: calc(100% - 82px);
}

.news-item__date {
  opacity: .75;
}

.news-item__title {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
  margin-bottom: .25rem;
}

.news-item__description {
  line-height: 1.4;
  height: 2.45rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.news__list {
  position: absolute;
  top: 48px;
  left: 0;
  width: 100%;
  max-height: calc(100% - 48px - 48px);
  user-select: none;
}

.news__categories {
  height: 48px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 .5rem;
  white-space: nowrap;
  overflow-x: auto;

  background-image:
    linear-gradient(to right, #dedfe1, #dedfe1),
    linear-gradient(to right, #dedfe1, #dedfe1),
    linear-gradient(to right, rgba(0, 0, 20, .2), rgba(255, 255, 255, 0)),
    linear-gradient(to left, rgba(0, 0, 20, .2), rgba(255, 255, 255, 0));
  background-position: left center, right center, left center, right center;
  background-repeat: no-repeat;
  background-color: #dedfe1;
  background-size: 30px 100%, 30px 100%, 20px 100%, 20px 100%;
  background-attachment: local, local, scroll, scroll;
}

.news__category {
  color: var(--text-color);
  padding: 0 .75rem;
  line-height: 44px;
  font-size: .875rem;
  border: 0;
  border-top: 2px solid transparent;
  background-color: transparent;
  display: inline-block;
  vertical-align: top;
  margin: 0;
}

.news__category.is-active {
  border-top-color: var(--text-color);
  font-weight: 700;
}

.news__category:focus {
  outline: none;
  font-weight: 700;
}

@media (min-width: 700px) {
  .news__list {
    max-height: calc(100% - 48px);
  }
}
</style>
