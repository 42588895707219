import jsonp from 'jsonp';

const YOUTUBE_ID_LENGTH = 11;

const videoHelpers = {
  /**
   * Extract the video ID from a url
   * @param  {string} url - URL to extract the ID from
   * @return {string|boolean} ID or false if it's a invalid url
   */
  getYoutubeId(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/; // eslint-disable-line
    const match = url.match(regExp);
    const idFound = (match && match[2].length === YOUTUBE_ID_LENGTH);

    return (idFound) ? match[2] : false;
  },

  /**
   * Create YouTube embed HTML
   * @param  {string} id - ID of the video to embed
   * @return {string} YouTube embed HTML
   */
  createYoutubeEmbed(id) {
    return `
      <iframe
        width="560"
        height="315"
        src="//www.youtube.com/embed/${id}?rel=0&amp;autoplay=1&amp;color=white"
        frameborder="0"
        allowfullscreen></iframe>
      `;
  },

  /**
   * Get the poster from a YouTube video
   * @param  {string} id - YouTube video ID
   * @return {string} url of the YouTube poster
   */
  getYoutubePoster(id) {
    return `https://img.youtube.com/vi/${id}/maxresdefault.jpg`;
  },

  /**
   * Extract the ID from a Vimeo URL
   * @param  {string} url - Vimeo video url
   * @return {string|boolean} ID or false if it's a invalid url
   */
  getVimeoId(url) {
    const regExp = /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/; // eslint-disable-line
    const match = url.match(regExp);

    return (match) ? match[3] : false;
  },

  /**
   * Create Vimeo embed
   * @param  {string} id - Vimeo video ID
   * @return {string} Vimeo embed HTML
   */
  createVimeoEmbed(id) {
    return `
      <iframe
        src="https://player.vimeo.com/video/${id}?autoplay=1&color=ee7f00"
        width="640"
        height="360"
        frameborder="0"
        allowfullscreen></iframe>
    `;
  },

  /**
   * Get the Vimeo poster by requesting the video information
   * @param  {string} id - ID of the video to request the poster for
   * @param  {function} cb - Because the function is asynchronous we have to use a callback
   * @return {void}
   */
  getVimeoPoster(id, cb) {
    jsonp(`https://vimeo.com/api/v2/video/${id}.json`, (err, data) => {
      if (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        return;
      }

      const thumbnailSrc = data[0].thumbnail_large;
      cb.call(this, thumbnailSrc);
    });
  },

  videoType(url) {
    if (url.indexOf('youtu') >= 0) {
      return 'youtube';
    }

    if (url.indexOf('vimeo') >= 0) {
      return 'vimeo';
    }

    return null;
  },

  videoId(url) {
    if (videoHelpers.videoType(url) === 'youtube') {
      return videoHelpers.getYoutubeId(url);
    }

    if (videoHelpers.videoType(url) === 'vimeo') {
      return videoHelpers.getVimeoId(url);
    }

    return null;
  },

  videoPoster(url) {
    return new Promise((resolve, reject) => {
      if (videoHelpers.videoType(url) === 'youtube') {
        return resolve(videoHelpers.getYoutubePoster(videoHelpers.videoId(url)));
      }

      if (videoHelpers.videoType(url) === 'vimeo') {
        return videoHelpers.getVimeoPoster(videoHelpers.videoId(url), resolve);
      }

      return reject(new Error('Can\'t create poster'));
    });
  },

  videoEmbed(url) {
    if (url.indexOf('youtu') >= 0) {
      return videoHelpers.createYoutubeEmbed(videoHelpers.videoId(url));
    }

    if (url.indexOf('vimeo') >= 0) {
      return videoHelpers.createVimeoEmbed(videoHelpers.videoId(url));
    }

    return null;
  },

  loadYoutubeApi() {
    return new Promise((resolve) => {
      if (typeof window.YT !== 'undefined') {
        resolve(window.YT);
        return;
      }

      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      document.head.appendChild(tag);

      window.onYouTubeIframeAPIReady = () => {
        resolve(window.YT);
      };
    });
  },
};

export default videoHelpers;
