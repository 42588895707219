<template>
  <div class="news-detail">
    <title-bar
      :left="{ path: '/news', text: 'Nieuws', icon: 'chevron-left' }"
      title="Nieuwsbericht"
    />

    <div class="content-section">
      <carousel
        v-if="newsItem && newsItem.images && newsItem.images.length"
        :per-page="1"
        :pagination-padding="0"
      >
        <slide v-for="image in newsItem.images" :key="image">
          <img :src="image" alt="" class="w-100 d-block" />
        </slide>
      </carousel>

      <div class="news-detail__content">
        <strong class="news-detail__heading" v-if="shallowNewsItem">
          {{ shallowNewsItem.title }}
        </strong>

        <div>
          {{ date }} - {{ shallowNewsItem ? shallowNewsItem.categoryName : 'Onbekend' }}
        </div>

        <div v-if="newsItem">
          <div v-html="newsItem.content" class="mb-3">
          </div>

          <div v-if="files">
            <strong class="d-block mb-2">
              Bestanden
            </strong>
            <a
              v-for="item in files"
              :key="item.filename"
              :href="item.url"
              class="btn btn--primary d-block mb-2"
            >
              {{ item.filename }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { format, parseISO } from 'date-fns';
import TitleBar from '../../components/TitleBar.vue';

export default {
  name: 'newsItem',

  components: {
    TitleBar,
  },

  data() {
    return {
    };
  },

  mounted() {
    const { id } = this.$route.params;

    this.fetchNews();
    this.fetchNewsItem(id);
  },

  destroyed() {
    this.unsetNewsItem();
  },

  computed: {
    ...mapGetters([
      'news',
      'newsItem',
    ]),

    shallowNewsItem() {
      const { id } = this.$route.params;

      const filteredNews = this.news ? this.news.filter((newsItem) => (
        newsItem.id === parseInt(id, 10)
      )) : [];

      return filteredNews.length ? filteredNews[0] : null;
    },

    date() {
      if (!this.shallowNewsItem) {
        return '';
      }

      const { date } = this.shallowNewsItem;

      return date ? format(parseISO(date), 'dd-MM-yyyy') : '';
    },

    files() {
      if (!this.newsItem || !this.newsItem.files || !this.newsItem.files.length) {
        return null;
      }

      return this.newsItem.files.map((fileUrl) => ({
        url: fileUrl,
        filename: fileUrl.split('/')[fileUrl.split('/').length - 1],
      }));
    },
  },

  methods: {
    ...mapActions([
      'fetchNews',
      'fetchNewsItem',
      'unsetNewsItem',
    ]),
  },
};
</script>

<style scoped>
.news-detail {
  height: 100%;
}

.news-detail__content {
  padding: .875rem;
}

.news-detail__heading {
  font-size: 1.125rem;
  display: block;
  margin-bottom: .25rem;
}
</style>

<style>
.VueCarousel-pagination {
  background-color: #fafafa;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.VueCarousel-dot {
  margin-left: 4px;
  margin-right: 4px;
}

.VueCarousel-dot:not(.VueCarousel-dot--active) {
  background-color: #999 !important;
}
</style>
