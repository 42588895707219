<template>
  <div class="time-entries h-100">
    <title-bar
      :leftbutton="{ text: 'Ververs', action: refreshEntries, icon: 'sync' }"
      title="Uren overzicht"
    />

    <time-entry-tab-bar />

    <div class="content-section">
      <ul class="time-entries__list">
        <li
          class="time-entries__item"
          v-for="timesheet in timesheetsList"
          :key="timesheet.id"
        >
          <div class="time-entries__status" :data-approved="timesheet.approved">
            <span>
              <font-awesome-icon icon="check" v-if="timesheet.approved" />
              <font-awesome-icon icon="spinner" v-if="!timesheet.approved" />
            </span>
          </div>

          <div class="time-entries__item-meta">
            <h2 class="time-entries__item-heading">
              {{
                timesheet.custom_project_name
                  ? timesheet.custom_project_name
                  : timesheet.project_name
              }}
              {{ timesheet.custom_project_number ? `- ${timesheet.custom_project_number}` : '' }}
              - {{ timesheet.shift_name }}
            </h2>

            <div class="time-entries__project">
              <strong>Project</strong><br>
              <span class="time-entries__ellipsis">
                {{ timesheet.project_name }}
              </span>
            </div>

            <div class="time-entries__shift">
              <strong>Dienst</strong><br>
              <span class="time-entries__ellipsis">
                {{ timesheet.shift_name }}
              </span>
            </div>

            <div class="time-entries__item-duration">
              <div class="time-entries__from">
                <strong>{{ timesheet.formattedStartDate }}</strong><br>
                {{ timesheet.formattedStartTime }}
              </div>

              <div class="time-entries__to">
                <strong>{{ timesheet.formattedEndDate }}</strong><br>
                {{ timesheet.formattedEndTime }}
              </div>
            </div>

            <div class="time-entries__comment" v-if="timesheet.comment">
              {{ timesheet.comment }}
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import parseISO from 'date-fns/parseISO';
import { mapActions, mapGetters } from 'vuex';
import TitleBar from '../../components/TitleBar.vue';
import TimeEntryTabBar from './TimeEntryTabBar.vue';
import format from '../../helpers/format';
import formatTime from '../../helpers/formatTime';

export default {
  name: 'time-entries',

  components: {
    TimeEntryTabBar,
    TitleBar,
  },

  data() {
    return {
      //
    };
  },

  computed: {
    ...mapGetters([
      'timesheets',
    ]),

    timesheetsList() {
      return this.timesheets.map((timesheet) => {
        const clone = { ...timesheet };

        clone.formattedStartTime = formatTime(clone.start_time);
        clone.formattedEndTime = formatTime(clone.end_time);

        clone.formattedStartDate = format(parseISO(clone.start_date), 'EEEE d MMMM yyyy');
        clone.formattedEndDate = format(parseISO(clone.end_date), 'EEEE d MMMM yyyy');

        return clone;
      });
    },
  },

  methods: {
    ...mapActions([
      'fetchTimeEntries',
      'fetchUser',
    ]),

    refreshEntries() {
      this.fetchTimeEntries();
      this.fetchUser();
    },
  },

  watch: {
    $route: 'refreshEntries',
  },

  mounted() {
    this.refreshEntries();
  },
};
</script>

<style scoped>
.time-entries .content-section {
  max-height: calc(100% - 94px);
}

.time-entries__list {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.time-entries__item {
  padding: 1rem 1.125rem;
  border-bottom: 1px solid #e3e3e3;
  border-top: 1px solid transparent;
  line-height: 1.4;
  display: flex;
  align-items: center;
}

.time-entries__status {
  margin-right: 1rem;
}

.time-entries__status span {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
}

.time-entries__status[data-approved='0'] span {
  animation: spin 4s infinite linear;
}

.time-entries__status[data-approved='1'] span {
  background-color: var(--success-color);
  color: #fff;
}

.time-entries__from,
.time-entries__to,
.time-entries__project,
.time-entries__shift {
  min-width: 110px;
  padding-right: 1rem;
}

.time-entries__item-heading {
  margin: 0 0 .25rem;
  font-size: .9375rem;
}

.time-entries__item-duration {
  display: flex;
  align-items: center;
}

.time-entries__project,
.time-entries__shift {
  display: none;
}

.time-entries__ellipsis {
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

.time-entries__comment {
  margin-top: .375rem;
}

@media (min-width: 1024px) {
  .time-entries__item-heading {
    display: none;
  }

  .time-entries__item-meta {
    display: flex;
    align-items: center;
  }

  .time-entries__project,
  .time-entries__shift {
    display: block;
  }

  .time-entries__from,
  .time-entries__to,
  .time-entries__project,
  .time-entries__shift {
    width: 155px;
  }
}

@media (min-width: 1200px) {
  .time-entries__from,
  .time-entries__to,
  .time-entries__project,
  .time-entries__shift {
    width: 210px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
</style>
