<template>
  <div class="quiz">
    <laravel-errors
      v-if="errors && Object.keys(errors).length"
      :errors="errors"
      class="quiz__errors"
    />

    <quiz-slides
      v-if="state === 'slides'"
      @next-slide="nextSlide"
      @previous-slide="previousSlide"
      @to-questions="toQuestions"
      :activeSlide="activeSlide"
      :slides="slides"
    />

    <quiz-questions
      v-if="state === 'questions'"
      @next-question="nextQuestion"
      @previous-question="previousQuestion"
      @to-questions="toQuestions"
      @answer-correct="onCorrectAnswer"
      @answer-wrong="onWrongAnswer"
      :activeQuestion="activeQuestion"
      :questions="questions"
    />

    <div
      v-if="state === 'complete'"
      class="quiz__card is-active"
    >
      <h2 class="quiz__card-title">
        Je hebt de test afgerond!
      </h2>

      <div class="mb-2">
        <strong class="d-block">Medewerker</strong>
        {{ user.fullname }}
      </div>

      <div class="mb-2">
        <strong class="d-block">Datum</strong>
        {{ currentDate }}
      </div>

      <div class="quiz__card-actions">
        <button
          type="button"
          class="quiz__card-action btn btn--success"
          @click="onSubmit"
          :disabled="vcaToolboxStoreLoadingState === 'loading'"
        >
          <font-awesome-icon icon="check" class="mr-1" />
          {{
            vcaToolboxStoreLoadingState === 'loading'
              ? 'Een moment geduld...'
              : 'Akkoord en opslaan'
          }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import QuizSlides from './QuizSlides.vue';
import QuizQuestions from './QuizQuestions.vue';
import format from '../../helpers/format';
import LaravelErrors from '../../components/LaravelErrors.vue';

export default {
  components: {
    LaravelErrors,
    QuizQuestions,
    QuizSlides,
  },

  props: {
    toolbox: Object,
  },

  data() {
    return {
      state: 'slides',
      activeSlide: 0,
      activeQuestion: 0,
      tries: 0,
      errors: [],
      givenAnswers: [],
    };
  },

  mounted() {
    // A test requires slides and questions. If those are missing, something is
    // wrong and we send the user back to the overview.
    if (typeof this.toolbox.questions === 'undefined' || typeof this.toolbox.slides === 'undefined') {
      this.$router.push({ path: '/vca-toolbox' });
      this.$toasted.show('Probleem bij ophalen van de test, probeer het later opnieuw', { type: 'error' }).goAway(2500);
    }
  },

  computed: {
    ...mapGetters([
      'user',
      'vcaToolboxStoreLoadingState',
    ]),

    questions() {
      return this.toolbox.questions;
    },

    slides() {
      return this.toolbox.slides;
    },

    currentDate() {
      return format(new Date(), 'EEEE d MMMM yyyy');
    },
  },

  methods: {
    ...mapActions([
      'storeVcaToolbox',
    ]),

    nextSlide() {
      this.activeSlide += 1;
    },

    previousSlide() {
      const previousSlide = this.activeSlide - 1;

      this.activeSlide = previousSlide >= 0 ? previousSlide : 0;
    },

    nextQuestion() {
      this.activeQuestion += 1;

      if (this.activeQuestion >= this.questions.length) {
        this.storeIncompleteTest();

        this.state = 'complete';
      }
    },

    previousQuestion() {
      const previousQuestion = this.activeQuestion - 1;

      this.activeQuestion = previousQuestion >= 0 ? previousQuestion : 0;
    },

    toQuestions() {
      this.state = 'questions';
    },

    onCorrectAnswer(question, answer) {
      this.givenAnswers.push({
        correct: true,
        question: question.title,
        answer,
      });
    },

    onWrongAnswer(question, answer) {
      this.tries += 1;

      this.givenAnswers.push({
        correct: false,
        question: question.title,
        answer,
      });
    },

    storeIncompleteTest() {
      this.storeVcaToolbox({
        completed_at: format(new Date(), 'yyyy-MM-dd'),
        vca_test_id: this.toolbox.id,
        tries: this.tries,
        accepted: 0,
        answers: this.givenAnswers,
      })
        .then(() => {
          //
        })
        .catch((err) => {
          this.handleResponseErrors(err);
        });
    },

    onSubmit() {
      this.storeVcaToolbox({
        completed_at: format(new Date(), 'yyyy-MM-dd'),
        vca_test_id: this.toolbox.id,
        tries: this.tries,
        accepted: 1,
        answers: this.givenAnswers,
      })
        .then(() => {
          this.$router.push({ name: 'vca-toolbox' });
          this.$toasted.show('We hebben je gegevens ontvangen.', { type: 'success' })
            .goAway(2500);
        })
        .catch((err) => {
          this.handleResponseErrors(err);
        });
    },

    handleResponseErrors(err) {
      // Check if the error has a response (from the back-end). If not
      // there is something else going wrong...
      if (typeof err.data === 'undefined' || typeof err.data.errors === 'undefined') {
        this.$toasted.show('Er ging iets mis bij het opslaan.', { type: 'error' }).goAway(2500);
        return;
      }

      this.errors = err.data.errors;
    },
  },
};
</script>

<style>
.quiz {
  padding: 15px;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.quiz__card {
  padding: 15px;
  box-shadow: 0 2px 15px -4px rgba(0, 0, 0, .2), 0 2px 5px -4px rgba(0, 0, 0, .4);
  border-radius: 10px;
  position: absolute;
  top: 15px;
  left: 15px;
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  transform: translateX(calc(100% + 10px));
  overflow-y: auto;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  max-width: 720px;
  transition: transform .3s cubic-bezier(0.25, 1, 0.5, 1), opacity .3s ease-out;
}

.quiz__card.is-active,
.quiz__card.is-completed {
  transform: translateX(0);
}

.quiz__card-title {
  margin: 0 0 15px;
  text-align: center;
}

.quiz__card-actions {
  margin-top: auto;
}

.quiz__card-action {
  width: 100%;
  display: block;
  margin-bottom: 10px;
}

.quiz__card-action:last-child {
  margin-bottom: 0;
}

.quiz__errors {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}

.quiz__card-media {
  margin-bottom: 15px;
}

.quiz__card-media-image {
  max-width: 100%;
  height: auto;
  border-radius: .25rem;
}

.quiz__card-media--video {
  padding-bottom: 56.25%;
  position: relative;
  border-radius: .25rem;
  overflow: hidden;
}

.quiz__card-media--video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (min-width: 768px) {
  .quiz__card {
    opacity: 0;
  }

  .quiz__card.is-active,
  .quiz__card.is-completed {
    opacity: 1;
  }
}
</style>
