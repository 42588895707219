<template>
  <div class="contacts">
    <title-bar
      :leftbutton="{ text: 'Ververs', action: onRefreshClick }"
      title="Planning"
      :right="{ path: '/planning/filter', text: 'Filter' }"
    />

    <div class="calendar">
      <div class="calendar__weeks" :style="{ paddingTop: `${weekNumbersOffset}px` }">
        <div
          v-for="weekNumber in weekNumbers"
          :style="{ height: `${weekNumberHeight}px` }"
          :key="weekNumber"
        >
          {{weekNumber}}
        </div>
      </div>

      <v-calendar
        class="calendar__calendar"
        :attributes="planning"
        :columns="1"
        transition="none"
        ref="calendar"
        locale="nl"
        is-expanded
        @dayclick="onDayClick"
        @update:to-page="onPageUpdate"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import currentWeekNumber from 'current-week-number';
import TitleBar from '../../components/TitleBar.vue';

export default {
  name: 'planning',

  components: {
    TitleBar,
  },

  data() {
    return {
      weekNumbersOffset: 0,
      weekNumberHeight: 0,
      weekNumbers: [],
    };
  },

  computed: {
    ...mapGetters([
      'planning',
      'calendarState',
    ]),
  },

  methods: {
    ...mapActions([
      'fetchPlanning',
      'setCalendarState',
    ]),

    onDayClick(dayItem) {
      this.$router.push(`/planning/${dayItem.id}`);
    },

    onPageUpdate(calendarStateInformation) {
      this.setCalendarState(calendarStateInformation);

      setTimeout(() => {
        this.getWeekNumbers();
      }, 400);
    },

    onRefreshClick() {
      this.fetchPlanning(true);
    },

    onResize() {
      if (typeof this.$refs.calendar === 'undefined') {
        return;
      }

      const calendar = this.$refs.calendar.$el;
      const firstCell = calendar.querySelector('.vc-weeks .vc-day');

      const calendarTop = calendar.getBoundingClientRect().top;
      const firstCellTop = firstCell.getBoundingClientRect().top;
      const firstCellHeight = firstCell.getBoundingClientRect().height;

      this.weekNumbersOffset = firstCellTop - calendarTop;
      this.weekNumberHeight = firstCellHeight;
    },

    getWeekNumbers() {
      if (typeof this.$refs.calendar === 'undefined') {
        return;
      }

      const cellsNodeList = this.$refs.calendar.$el.querySelectorAll('.vc-weeks .vc-day');
      const cells = Array.prototype.slice.call(cellsNodeList);

      const firstForRows = cells.filter((cell) => cell.classList.contains('weekday-position-1'));
      const weekNumbers = [];

      firstForRows.forEach((cell) => {
        const matchDate = /id-(\d{4}-\d{2}-\d{2})/g.exec(cell.className);
        const date = matchDate[1];
        weekNumbers.push(currentWeekNumber(date));
      });

      this.weekNumbers = weekNumbers;
    },
  },

  mounted() {
    this.fetchPlanning();

    window.addEventListener('resize', this.onResize.bind(this));
    this.onResize();
    this.getWeekNumbers();

    this.interval = setInterval(() => {
      this.fetchPlanning();
    }, 60000 * 5);
  },

  beforeDestroy() {
    if (typeof this.interval !== 'undefined') {
      clearInterval(this.interval);
    }
  },
};
</script>

<style>
.vc-container {
  border: 0;
}

.calendar .vc-weeks {
  min-height: 360px;
  grid-template-rows: 20px repeat(6, 1fr);
}

.calendar .vc-weekday {
  height: 24px;
}

.calendar .vc-dots {
  top: -.25rem;
  position: relative;
}

.calendar .vc-header {
  padding-bottom: 10px !important;
}

.is-today .vc-day-content {
  background: #e9e9e9;
  font-weight: 700;
}

.calendar {
  display: flex;
}

.calendar__calendar {
  min-width: 0 !important;
  width: 100% !important;
  border: 0 !important;
}

.calendar__weeks {
  width: 3rem;
  text-align: center;
  border-right: 1px solid #ddd;
  color: #999;
}

.calendar__weeks > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 375px) and (min-height: 667px) {
  .calendar .vc-weeks {
    min-height: 530px;
  }

  .calendar .vc-dots {
    top: -1rem;
  }
}
</style>
