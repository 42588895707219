export default {
  sick: 'Ziek',
  absence: 'Niet beschikbaar',
  vacation: 'Vakantie',
  holiday: 'Vrije dag',
  dentist: 'Tandarts',
  doctor: 'Dokter',
  '55-day': '55+ dag',
  'tvt-day': 'TvT vrije dag',
  'unpaid-leave': 'Onbetaald verlof',
  'time-savings-fund-free-day': 'Tijdspaarfonds vrije dag',
};
