import ApiRequest from '../helpers/ApiRequest';

const TimeEntryStore = {
  state: {
    toReview: [],
    timesheets: [],
    timesheetShifts: [],
    timeEntryLoadingState: 'idle',
    timeEntryStoreLoadingState: 'idle',
    timeEntryApprovalLoadingState: 'idle',
  },

  getters: {
    toReview: (state) => state.toReview,
    timesheets: (state) => state.timesheets,
    timesheetShifts: (state) => state.timesheetShifts,
    timeEntryLoadingState: (state) => state.timeEntryLoadingState,
    timeEntryStoreLoadingState: (state) => state.timeEntryStoreLoadingState,
    timeEntryApprovalLoadingState: (state) => state.timeEntryApprovalLoadingState,
  },

  mutations: {
    setToReview(state, toReview) {
      state.toReview = toReview;
    },

    setTimesheetShifts(state, timesheets) {
      state.timesheetShifts = timesheets;
    },

    setTimesheets(state, timesheets) {
      state.timesheets = timesheets;
    },

    setTimeEntryLoadingState(state, loadingState) {
      state.timeEntryLoadingState = loadingState;
    },

    setTimeEntryApprovalLoadingState(state, loadingState) {
      state.timeEntryApprovalLoadingState = loadingState;
    },

    setTimeEntryStoreLoadingState(state, loadingState) {
      state.timeEntryStoreLoadingState = loadingState;
    },
  },

  actions: {
    async fetchTimeEntries({ commit, rootState }) {
      commit('setTimeEntryLoadingState', 'loading');

      try {
        const res = await ApiRequest.authorizedGet('/time-entry', rootState.user.currentJWT);

        const { data } = res;

        commit('setToReview', data.to_review);
        commit('setTimesheets', data.timesheets);
        commit('setTimesheetShifts', data.shifts);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
        commit('setTimeEntryLoadingState', 'error');
        return;
      }

      commit('setTimeEntryLoadingState', 'idle');
    },

    async approveTimeEntry({ commit, rootState }, formData) {
      commit('setTimeEntryApprovalLoadingState', 'loading');

      let promise;

      try {
        promise = await ApiRequest.authorizedPost('/approve-time-entry', formData, rootState.user.currentJWT);

        commit('setTimeEntryApprovalLoadingState', 'idle');
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
        commit('setTimeEntryApprovalLoadingState', 'error');
        return Promise.reject();
      }

      return promise;
    },

    async disapproveTimeEntry({ rootState }, formData) {
      let promise;

      try {
        promise = await ApiRequest.authorizedPost('/disapprove-time-entry', formData, rootState.user.currentJWT);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
        return Promise.reject();
      }

      return promise;
    },

    async storeTimeEntry({ rootState, commit }, formData) {
      commit('setTimeEntryStoreLoadingState', 'loading');

      let promise;

      try {
        promise = await ApiRequest.authorizedPost('/time-entry', formData, rootState.user.currentJWT);

        commit('setTimeEntryStoreLoadingState', 'idle');
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
        commit('setTimeEntryStoreLoadingState', 'error');
        return Promise.reject(error);
      }

      return promise;
    },
  },
};

export default TimeEntryStore;
