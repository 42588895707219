<template>
  <modal
    class="intro-modal"
    :name="modalKey"
    adaptive
    height="auto"
  >
    <div class="mb-3">
      <div v-if="step === 0">
        <div class="intro-modal__title">
          Veiligheidsmelding
        </div>

        <div class="intro-modal__text">
          De Wilde BV hecht veel waarde aan veiligheid. Heeft u tips/opmerkingen die
          ons werk veiliger kunnen maken dan kunt u dit laten weten door een
          &ldquo;veiligheidsmelding&rdquo; te doen.
        </div>
      </div>

      <div v-if="step === 1">
        <div class="intro-modal__title">
          Nieuwsberichten
        </div>

        <div class="intro-modal__text">
          Onder &ldquo;Nieuws&rdquo; houden we up op de hoogte van het laatste ontwikkelingen.
        </div>
      </div>

      <div v-if="step === 2">
        <div class="intro-modal__title">
          Toevoegen aan home
        </div>

        <div class="intro-modal__text mb-3">
          U kunt deze app heel gemakkelijk op uw telefoon plaatsen.
        </div>

        <img
          class="d-block w-100"
          :src="homeScreenTutorialImage"
          alt="Toevoegen aan home scherm"
        >
      </div>
    </div>

    <div class="d-flex">
      <button
        class="btn btn--muted"
        type="button"
        @click="onPrevClick"
        v-if="!isFirstStep"
      >
        Vorige
      </button>

      <button
        :class="`btn ${isLastStep ? 'btn--primary ml-auto' : 'btn--muted'}`"
        type="button"
        @click="onCloseClick"
        v-if="isFirstStep || isLastStep"
      >
        Sluiten
      </button>

      <button
        class="btn btn--primary ml-auto"
        type="button"
        @click="onNextClick"
        v-if="!isLastStep"
      >
        Volgende
      </button>
    </div>
  </modal>
</template>

<script>
export default {
  props: {
    modalKey: {
      type: String,
    },
  },

  data() {
    return {
      step: 0,
      steps: 3,
    };
  },

  mounted() {
    if (!this.shouldShowHomeScreenGuide) {
      this.steps -= 1;
    }
  },

  computed: {
    isFirstStep() {
      return this.step === 0;
    },

    isLastStep() {
      return this.step === (this.steps - 1);
    },

    deviceType() {
      if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
        return 'ios';
      }

      if (navigator.userAgent.toLowerCase().indexOf('android') >= 0) {
        return 'android';
      }

      return 'unknown';
    },

    shouldShowHomeScreenGuide() {
      return (
        typeof window.matchMedia === 'function'
        && !window.matchMedia('(display-mode: standalone)').matches
        && this.deviceType !== 'unknown'
      );
    },

    homeScreenTutorialImage() {
      return `/img/homescreen-${this.deviceType === 'ios' ? 'ios' : 'android'}.jpg`;
    },
  },

  methods: {
    onCloseClick() {
      this.$modal.hide('intro');
    },

    onNextClick() {
      this.step += 1;
    },

    onPrevClick() {
      this.step -= 1;
    },
  },
};
</script>

<style>
.intro-modal .v--modal-box {
  margin-left: 15px;
  margin-right: 15px;
  max-width: calc(100% - 30px);
  padding: .875rem;
  line-height: 1.4;
}

.intro-modal__title {
  font-size: 1.125rem;
  font-weight: 700;
  display: block;
  margin-bottom: .875rem;
}

.intro-modal__text {
  font-size: 1rem;
}

.intro-modal__button {
  background-color: var(--primary-color);
  color: #fff;
  padding: .6rem .75rem;
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
}

@media (min-width: 768px) {
  .intro-modal .v--modal-box {
    padding: 1.5rem;
  }
}
</style>
