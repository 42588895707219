import ApiRequest from '../helpers/ApiRequest';
import isTimestampExpired from '../helpers/IsTimestampExpired';

const NewsStore = {
  state: {
    news: null,
    newsFiltered: null,
    newsItem: null,
    newsFetchDate: null,
    newsCategory: null,
  },

  getters: {
    news(state) {
      return state.news;
    },

    newsFiltered(state) {
      return state.newsFiltered;
    },

    newsItem(state) {
      return state.newsItem;
    },

    newsCategory(state) {
      return state.newsCategory;
    },
  },

  mutations: {
    setNews(state, news) {
      state.news = news;
      state.newsFiltered = news;
      state.newsCategory = null;
    },

    setNewsItem(state, newsItem) {
      state.newsItem = newsItem;
    },

    setNewsCategory(state, newsCategory) {
      state.newsCategory = newsCategory;

      if (!newsCategory) {
        state.newsFiltered = state.news;
        return;
      }

      state.newsFiltered = state.news.filter((newsItem) => (
        newsItem.category === newsCategory
      ));
    },

    unsetNewsItem(state) {
      state.newsItem = null;
    },

    unsetNewsCategory(state) {
      state.newsCategory = null;
    },

    setNewsFetchDate(state, timestamp) {
      state.newsFetchDate = timestamp;
    },
  },

  actions: {
    async fetchNews({ rootState, commit, state }) {
      const now = new Date().getTime();
      const cacheTime = 2 * 60 * 1000; // 2 minutes

      // Check if we've news items available that were fetched in the last few minutes
      if (
        state.news
        && state.newsFetchDate
        && !isTimestampExpired(state.newsFetchDate, cacheTime)
      ) {
        return;
      }

      let params = '';

      if (typeof rootState.user !== 'undefined' && rootState.user) {
        const { user } = rootState.user;

        if (typeof user !== 'undefined' && user && typeof user.id !== 'undefined') {
          params = `?user_id=${user.id}`;
        }
      }

      const res = await ApiRequest.get(`/information${params}`);

      commit('setNews', res.data);
      commit('setNewsFetchDate', now);
    },

    async fetchNewsItem({ commit }, id) {
      const res = await ApiRequest.get(`/information/${id}`);

      commit('setNewsItem', res.data);
    },

    setNewsCategory({ commit }, newsCategory) {
      commit('setNewsCategory', newsCategory);
    },

    unsetNewsItem({ commit }) {
      commit('unsetNewsItem');
    },
  },
};

export default NewsStore;
