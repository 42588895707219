<template>
  <div class="navigation">
    <div class="navigation__head">
      De Wilde
    </div>

    <router-link class="navigation__item" to="/">
      <font-awesome-icon icon="tachometer-alt" fixed-width />
      Dashboard
    </router-link>

    <router-link class="navigation__item" to="/news">
      <font-awesome-icon icon="newspaper" fixed-width />
      Nieuws
    </router-link>

    <router-link class="navigation__item" to="/planning" v-if="isLoggedIn">
      <font-awesome-icon icon="calendar" fixed-width />
      Planning
    </router-link>

    <router-link class="navigation__item" to="/contacts" v-if="isLoggedIn">
      <font-awesome-icon icon="users" fixed-width />
      Contacten
    </router-link>

    <router-link
      class="navigation__item navigation__item--lg"
      to="/safety-contact"
    >
      <font-awesome-icon icon="exclamation-triangle" fixed-width />
      Veiligheidsmelding doen
    </router-link>

    <router-link
      class="navigation__item navigation__item--lg"
      to="/kilometer-registrations"
      v-if="isLoggedIn"
    >
      <font-awesome-icon icon="car" fixed-width />
      Kilometer registratie
    </router-link>

    <router-link
      class="navigation__item navigation__item--lg"
      to="/time-entry"
      v-if="isLoggedIn"
    >
      <font-awesome-icon icon="user-clock" fixed-width />
      Uren registratie
    </router-link>

    <router-link
      class="navigation__item navigation__item--lg"
      to="/vca-toolbox"
      v-if="isLoggedIn"
    >
      <font-awesome-icon icon="hard-hat" fixed-width />
      Toolbox
    </router-link>

    <router-link
      class="navigation__item navigation__item--lg"
      to="/leave"
      v-if="isLoggedIn"
    >
      <font-awesome-icon icon="plane" fixed-width />
      Verlof
    </router-link>

    <router-link class="navigation__item navigation__item--lg" to="/settings" v-if="isLoggedIn">
      <font-awesome-icon icon="cog" fixed-width />
      Instellingen
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters([
      'isLoggedIn',
    ]),
  },
};
</script>

<style scoped>
.navigation {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 48px;
  background-color: var(--primary-color);
  display: flex;
  z-index: 1;
}

.is-ios .navigation {
  bottom: var(--safe-area-inset-bottom);
}

.navigation:before {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: var(--safe-area-inset-bottom);
  background-color: var(--primary-color);
}

.navigation__item {
  flex: 1 1 25%;
  max-width: 25%;
  text-align: center;
  padding: .5rem .75rem;
  display: inline-block;
  text-decoration: none;
  color: var(--text-color-inverse);
  user-select: none;
}

.navigation__item.router-link-exact-active {
  background-color: var(--primary-color-shade);
}

.navigation__item--lg {
  display: none;
}

.navigation__item svg {
  display: block;
  margin: -.05rem auto .15rem;
}

.navigation__head {
  display: none;
}

@media (min-width: 700px) {
  .navigation {
    bottom: auto;
    top: 0;
    width: 220px;
    display: block;
    height: 100%;
    background-color: #f4f5f7;
  }

  .navigation__item {
    display: block;
    max-width: 100%;
    text-align: left;
    padding: .875rem 1rem;
    border-bottom: 1px solid #dbdde0;
    color: var(--text-color);
  }

  .navigation__item.router-link-exact-active {
    background-color: #dee2e6;
    font-weight: 700;
  }

  .navigation__item--lg {
    display: block;
  }

  .navigation__item svg {
    display: inline-block;
    vertical-align: middle;
    margin-right: .25rem;
  }

  .navigation__head {
    display: block;
    background-color: var(--title-bar-background-color);
    border-right: 1px solid #002a58;
    line-height: 48px;
    color: #fff;
    padding-left: 1rem;
    font-size: 1rem;
    font-weight: 700;
  }
}

@media (min-width: 1200px) {
  .navigation {
    width: 300px;
  }
}
</style>
