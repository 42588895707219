<template>
  <v-date-picker
    v-model="date"
    ref="datepicker"
    mode="dateTime"
    is24hr
    :popover="{ positionFixed: true, visibility: 'focus' }"
    :min-date="minDate"
    :max-date="maxDate"
    locale="nl"
  >
    <template v-slot="{ inputValue, inputEvents }">
      <input
        class="form-control"
        :id="id"
        :value="inputValue"
        v-on="inputEvents"
        required
      />
    </template>

    <template v-slot:footer>
      <button
        class="btn btn--primary w-100"
        type="button"
        @click="$refs.datepicker.hidePopover"
      >
        Sluit
      </button>
    </template>
  </v-date-picker>
</template>

<script>
export default {
  props: {
    id: String,
    value: Date,
    minDate: Date,
    maxDate: Date,
  },

  data() {
    return {
      date: this.value,
    };
  },

  watch: {
    value() {
      this.date = this.value;
    },

    date() {
      this.$emit('input', this.date);
    },
  },
};
</script>

<style>
.vc-popover-content-wrapper {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.vc-popover-content-wrapper .vc-popover-caret {
  display: none !important;
}
</style>
