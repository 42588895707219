<template>
  <modal
    class="planning-modal"
    :name="modalKey"
    adaptive
    height="auto"
  >
    <strong class="planning-modal__title">
      {{ planningItem.title }}
    </strong>

    <div v-if="planningItem.project_description" class="mb-3">
      <strong>Omschrijving</strong>
      <div>{{ planningItem.project_description }}</div>
    </div>

    <div v-if="planningItem.contact_point" class="mb-3">
      <strong>Contactinformatie</strong>
      <div v-if="planningItem.contact_point.client">
        {{ planningItem.contact_point.client }}
      </div>
      <div v-if="planningItem.contact_point.contact_person">
        {{ planningItem.contact_point.contact_person }}
      </div>
      <div v-if="planningItem.contact_point.phone_number">
        {{ planningItem.contact_point.phone_number }}
      </div>
    </div>

    <div v-if="address">
      <div v-if="address.street">
        <strong>Adres</strong>
        <div>{{ address.street }} {{ address.street_number }}</div>
        <div class="mb-3">{{ address.city }} {{ address.province }}</div>
      </div>

      <a
        class="planning-modal__button"
        v-if="mapsUrl"
        :href="mapsUrl"
        target="_blank"
        rel="nofollow noopener"
      >
        Bekijk in Maps
      </a>
    </div>

    <div v-if="typeof planningItem.attachments !== 'undefined' && planningItem.attachments.length">
      <strong class="d-block">
        Bijlagen
      </strong>

      <a
        :href="attachment.url"
        v-for="attachment in planningItem.attachments"
        :key="attachment.name"
        class="planning-modal__attachment-link"
      >
        {{ attachment.name }}
      </a>
    </div>
  </modal>
</template>

<script>
export default {
  props: {
    planningItem: {
      type: Object,
      default: null,
    },
    modalKey: {
      type: String,
    },
  },

  computed: {
    address() {
      return this.planningItem ? this.planningItem.address : null;
    },

    mapsUrl() {
      if (!this.address) {
        return null;
      }

      const base = 'https://www.google.com/maps/search/?api=1&query=';

      // If geocordinates are available
      if (this.address.latitude && this.address.longitude) {
        // There are occurrences where coordinates were not properly saved.
        // For example lat:52 lng:4. By checking if its the rounded value
        // we fallback to the address fields.
        const isDefaultLatitude = this.address.latitude === Math.round(this.address.latitude);
        const isDefaultLongitude = this.address.longitude === Math.round(this.address.longitude);

        if (!isDefaultLatitude && !isDefaultLongitude) {
          return `${base}${this.address.latitude}%2C${this.address.longitude}`;
        }
      }

      if (!this.address.street && this.address.latitude && this.address.longitude) {
        return `${base}${this.address.latitude}%2C${this.address.longitude}`;
      }

      const country = typeof this.address.country !== 'undefined' && this.address.country
        ? this.address.country
        : 'Nederland';

      const addressString = [
        this.address.street,
        this.address.street_number,
        this.address.city,
        country,
      ].filter((item) => item).join(' ');

      const query = encodeURIComponent(addressString);

      return `${base}${query}`;
    },
  },
};
</script>

<style>
.planning-modal .v--modal-box {
  margin-left: 15px;
  margin-right: 15px;
  max-width: calc(100% - 30px);
  padding: .875rem;
  line-height: 1.4;
}

.planning-modal__title {
  font-size: 1rem;
  display: block;
  margin-bottom: .875rem;
}

.planning-modal__button {
  background-color: var(--primary-color);
  color: #fff;
  padding: .6rem .75rem;
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
}

.planning-modal__attachment-link {
  color: var(--primary-color);
  display: block;
  padding: .25rem 0;
}
</style>
