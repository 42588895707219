<template>
  <div class="dashboard">
    <title-bar title="Inloggen" />

    <div class="content-section">
      <form
        action="#"
        method="POST"
        @submit="onSubmit"
        ref="loginForm"
      >
        <div class="alert alert--danger" v-if="loginState === 'error'">
          Er ging iets mis bij het inloggen, mogelijk is je e-mailadres of wachtwoord onjuist.
        </div>

        <div class="form-group">
          <label for="email">E-mailadres</label>
          <input
            class="form-control"
            type="email"
            name="email"
            id="email"
            value=""
          >
        </div>

        <div class="form-group">
          <label for="password">Wachtwoord</label>
          <input
            class="form-control"
            type="password"
            name="password"
            id="password"
            value=""
          >
        </div>

        <div class="form-group">
          <button class="btn btn--primary w-100" type="submit" :disabled="loginState === 'loading'">
            {{ loginState === 'loading' ? 'Een moment geduld...' : 'Inloggen' }}
          </button>
        </div>

        <button
          class="btn btn--reset btn--full mt-3"
          type="button"
          @click="onPasswordResetClick"
        >
          Wachtwoord vergeten
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import TitleBar from '../../components/TitleBar.vue';

export default {
  name: 'login',

  components: {
    TitleBar,
  },

  data() {
    return {
      loginState: 'idle',
    };
  },

  computed: {
    ...mapGetters([
      'jwt',
      'jwtSubject',
      'jwtIssuer',
    ]),
  },

  methods: {
    ...mapActions([
      'fetchJWT',
    ]),

    onSubmit(e) {
      e.preventDefault();

      const { loginForm } = this.$refs;
      const data = new FormData(loginForm);

      Vue.set(this, 'loginState', 'loading');

      this.fetchJWT({
        email: data.get('email'),
        password: data.get('password'),
      })
        .then(() => {
          Vue.set(this, 'loginState', 'success');
          this.$router.push({ name: 'dashboard' });
        })
        .catch(() => {
          Vue.set(this, 'loginState', 'error');
        });
    },

    onPasswordResetClick() {
      this.$router.push({ name: 'forgot-password' });
    },
  },
};
</script>
