import Vue from 'vue';
import Router from 'vue-router';
import VModal from 'vue-js-modal';
import VueCarousel from 'vue-carousel';
import Toasted from 'vue-toasted';
import VCalendar from 'v-calendar';

import Dashboard from './views/Dashboard.vue';
import NotFound from './views/NotFound.vue';

// Auth
import Login from './views/Auth/Login.vue';
import ForgotPassword from './views/Auth/ForgotPassword.vue';
import Logout from './views/Auth/Logout.vue';

// Safety contact
import SafetyContact from './views/SafetyContact/SafetyContact.vue';

// Contact
import Contacts from './views/Contact/Contacts.vue';
import Contact from './views/Contact/Contact.vue';

// News
import News from './views/News/News.vue';
import NewsItem from './views/News/NewsItem.vue';

// Planning
import Planning from './views/Planning/Planning.vue';
import PlanningDetail from './views/Planning/PlanningDetail.vue';
import PlanningFilter from './views/Planning/PlanningFilter.vue';

// Leave
import Leave from './views/Leave/Leave.vue';
import LeaveRegistration from './views/Leave/LeaveRegistration.vue';

// Kilometer registration
import KilometerRegistration from './views/KilometerRegistration/KilometerRegistration.vue';
import KilometerRegistrations from './views/KilometerRegistration/KilometerRegistrations.vue';
import KilometerRegistrationEdit from './views/KilometerRegistration/KilometerRegistrationEdit.vue';

// Time entry
import TimeEntry from './views/TimeEntry/TimeEntry.vue';
import TimeEntries from './views/TimeEntry/TimeEntries.vue';
import TimeEntryApproval from './views/TimeEntry/TimeEntryApproval.vue';

// Toolbox
import VcaToolboxes from './views/VcaToolbox/VcaToolboxes.vue';
import VcaToolbox from './views/VcaToolbox/VcaToolbox.vue';

// Settings
import Settings from './views/Settings/Settings.vue';

Vue.use(Router);
Vue.use(VCalendar);
Vue.use(VModal);
Vue.use(VueCarousel);
Vue.use(Toasted, {
  position: 'bottom-left',
});

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'dashboard',
      component: Dashboard,
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/logout',
      name: 'logout',
      component: Logout,
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: ForgotPassword,
    },
    {
      path: '/safety-contact',
      name: 'safety-contact',
      component: SafetyContact,
    },
    {
      path: '/contacts',
      name: 'contacts',
      component: Contacts,
    },
    {
      path: '/contacts/:id',
      name: 'contact',
      component: Contact,
    },
    {
      path: '/news',
      name: 'news',
      component: News,
    },
    {
      path: '/news/:id',
      name: 'news-item',
      component: NewsItem,
    },
    {
      path: '/about',
      name: 'about',
      component: () => import(/* webpackChunkName: "about" */ './views/About/About.vue'),
    },
    {
      path: '/planning',
      name: 'planning',
      component: Planning,
    },
    {
      path: '/planning/filter',
      name: 'planning-filter',
      component: PlanningFilter,
    },
    {
      path: '/planning/:date',
      name: 'planning-detail',
      component: PlanningDetail,
    },
    {
      path: '/leave',
      name: 'leave',
      component: Leave,
    },
    {
      path: '/leave/registration',
      name: 'leave-registration',
      component: LeaveRegistration,
    },
    {
      path: '/kilometer-registration/edit/:id',
      name: 'kilometer-registration-edit',
      component: KilometerRegistrationEdit,
    },
    {
      path: '/kilometer-registration',
      name: 'kilometer-registration',
      component: KilometerRegistration,
    },
    {
      path: '/kilometer-registrations',
      name: 'kilometer-registrations',
      component: KilometerRegistrations,
    },
    {
      path: '/time-entry',
      name: 'time-entry',
      component: TimeEntry,
    },
    {
      path: '/time-entries',
      name: 'time-entries',
      component: TimeEntries,
    },
    {
      path: '/time-entry-approval',
      name: 'time-entry-approval',
      component: TimeEntryApproval,
    },
    {
      path: '/vca-toolbox/:id',
      name: 'vca-toolbox-view',
      component: VcaToolbox,
    },
    {
      path: '/vca-toolbox',
      name: 'vca-toolbox',
      component: VcaToolboxes,
    },
    {
      path: '/settings',
      name: 'settings',
      component: Settings,
    },
    {
      path: '*',
      name: 'not-found',
      component: NotFound,
    },
  ],
});
