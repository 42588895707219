<template>
  <div class="h-100">
    <title-bar back="/" title="Instellingen"></title-bar>

    <div class="content-section">
      <form
        action="#"
        method="POST"
        @submit="onSubmit"
        ref="settingsForm"
      >
        <div class="form-group">
          <label for="email">E-mailadres</label>
          <input
            class="form-control mb-1"
            type="text"
            name="email"
            id="email"
            :value="user.email"
            readonly
          >
          <div class="text-muted">
            Het e-mailadres kan alleen gewijzigd worden door de beheerder.
          </div>
        </div>

        <div class="form-group">
          <label for="phonenumber">Telefoonnummer</label>
          <input
            class="form-control mb-1"
            type="text"
            name="phonenumber"
            id="phonenumber"
            :value="user.phonenumber"
            readonly
          >
          <div class="text-muted">
            Het telefoonnummer kan alleen gewijzigd worden door de beheerder.
          </div>
        </div>

        <div class="form-group">
          <label class="mb-0" for="notification_mail">
            <input
              type="checkbox"
              name="notification_mail"
              id="notification_mail"
              value="1"
              :checked="user.notification_email"
            >
            Ontvang meldingen via e-mail
          </label>
        </div>

        <div class="form-group">
          <label class="mb-0" for="notification_sms">
            <input
              type="checkbox"
              name="notification_sms"
              id="notification_sms"
              value="1"
              :checked="user.notification_sms"
            >
            Ontvang meldingen via SMS
          </label>
        </div>

        <div class="alert alert--danger" v-if="formState === 'error'">
          Er ging iets mis bij het opslaan. Probeer het later opnieuw.
        </div>

        <div class="form-group">
          <button
            class="btn btn--primary btn--full"
            type="submit"
            :disabled="formState === 'loading'"
          >
            {{ formState === 'loading' ? 'Een moment geduld...' : 'Instellingen opslaan' }}
          </button>
        </div>

        <div class="form-group">
          <div>Versie: {{ versionNumber }}</div>
        </div>

        <div class="form-group">
          <button class="btn btn--muted mb-2" type="button" @click="$modal.show('intro')">
            Toon introductie
          </button>

          <br>

          <button class="btn btn--muted mb-2" type="button" @click="onUpdateClick">
            Update
          </button>

          <br>

          <button class="btn btn--muted mb-2" type="button" @click="onForceUpdateClick">
            Update geforceerd
          </button>

          <br>

          <button class="btn btn--muted" type="button" @click="onRefreshClick">
            Ververs pagina
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import TitleBar from '../../components/TitleBar.vue';
import packageJson from '../../../package.json';

export default {
  name: 'settings',

  components: {
    TitleBar,
  },

  data() {
    return {
      formState: 'idle',
      images: [],
      versionNumber: packageJson.version,
    };
  },

  computed: {
    ...mapGetters([
      'user',
    ]),
  },

  methods: {
    ...mapActions([
      'postSettingsForm',
      'fetchUser',
    ]),

    onSubmit(e) {
      e.preventDefault();

      const { settingsForm } = this.$refs;
      const data = new FormData(settingsForm);

      Vue.set(this, 'formState', 'loading');

      this.postSettingsForm(data)
        .then(() => {
          Vue.set(this, 'formState', 'success');
          this.fetchUser();
          this.$toasted.show('Instellingen zijn opgeslagen', { type: 'success' }).goAway(2000);
        })
        .catch(() => {
          Vue.set(this, 'formState', 'error');
        });
    },

    onForceUpdateClick() {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        registrations.forEach((registration) => {
          if (registration) {
            registration.unregister();
          }
        });
      });
    },

    onUpdateClick() {
      navigator.serviceWorker.getRegistration()
        .then((registration) => {
          if (registration) {
            registration.update();
          }
        });
    },

    onRefreshClick() {
      window.location.reload(true);
    },
  },
};
</script>
