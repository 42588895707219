<template>
  <div>
    <div
      :class="{
        'quiz__card': true,
        'is-active': questionIndex === activeQuestion,
        'is-completed': questionIndex < activeQuestion,
      }"
      v-for="(question, questionIndex) in questions"
      :key="question.id"
      :style="{ zIndex: questionIndex }"
    >
      <quiz-question
        :question="question"
        :is-active="questionIndex === activeQuestion"
        @answer-correct="(answer) => onCorrectAnswer(question, answer)"
        @answer-wrong="(answer) => onWrongAnswer(question, answer)"
      />
    </div>
  </div>
</template>

<script>
import QuizQuestion from './QuizQuestion.vue';

export default {
  props: {
    activeQuestion: Number,
    questions: Array,
  },

  components: {
    QuizQuestion,
  },

  methods: {
    onCorrectAnswer(question, answer) {
      setTimeout(() => {
        this.$emit('answer-correct', question, answer);
        this.$emit('next-question');
      }, 1000);
    },

    onWrongAnswer(question, answer) {
      this.$emit('answer-wrong', question, answer);
    },
  },
};
</script>
