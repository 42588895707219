<template>
  <div class="leave">
    <title-bar
      title="Verlof"
      :right="{ text: 'Aanvragen', path: '/leave/registration' }"
    />

    <virtual-list v-if="absencesList" class="leave__list" :size="56" :remain="15">
      <div class="leave-item" v-for="absence in absencesList" :key="absence.id">
        <div class="leave-item__content">
          <strong class="leave-item__heading d-block" v-if="absence.reason">
            {{ absence.reason }}
          </strong>
          <strong class="leave-item__heading d-block" v-else>
            {{ leaveTypes[absence.type] }}
          </strong>

          {{ absence.time }}
        </div>

        <div :class="`leave-item__status leave-item__status--${absence.status}`">
          <font-awesome-icon :icon="absence.statusIcon" />
        </div>
      </div>
    </virtual-list>
  </div>
</template>

<script>
// import Vue from 'vue';
import virtualList from 'vue-virtual-scroll-list';
import { mapActions, mapGetters } from 'vuex';
import { parseISO } from 'date-fns';
import leaveTypes from '../../leaveTypes';
import format from '../../helpers/format';
import TitleBar from '../../components/TitleBar.vue';

export default {
  name: 'leave',

  components: {
    virtualList,
    TitleBar,
  },

  data() {
    return {
      leaveTypes,
    };
  },

  computed: {
    ...mapGetters([
      'absences',
    ]),

    absencesList() {
      if (!this.absences) {
        return null;
      }

      return this.absences.map((absence) => {
        const clonedAbsence = { ...absence };

        const start = parseISO(absence.start_at);
        const end = parseISO(absence.end_at);

        if (absence.is_approved) {
          clonedAbsence.status = 'approved';
          clonedAbsence.statusIcon = 'check';
        } else if (!absence.is_approved && absence.reviewed_at) {
          clonedAbsence.status = 'denied';
          clonedAbsence.statusIcon = 'times';
        } else {
          clonedAbsence.status = 'pending';
          clonedAbsence.statusIcon = 'clock';
        }

        // If the same day
        if (format(start, 'dd-MM-yyyy') === format(end, 'dd-MM-yyyy')) {
          clonedAbsence.time = format(start, 'd MMMM yyyy');
          return clonedAbsence;
        }

        clonedAbsence.time = `${format(start, 'd MMMM yyyy')} – ${format(end, 'd MMMM yyyy')}`;
        return clonedAbsence;
      });
    },
  },

  methods: {
    ...mapActions([
      'fetchAbsences',
    ]),
  },

  mounted() {
    this.fetchAbsences();
  },
};
</script>

<style scoped>
.leave__list {
  position: absolute;
  top: 48px;
  left: 0;
  width: 100%;
  max-height: calc(100% - 48px);
  user-select: none;
}

.leave-item {
  font-size: .875rem;
  padding: .875rem;
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
}

.leave-item__heading {
  margin-bottom: .25rem;
}

.leave-item__content {
  padding-right: .75rem;
}

.leave-item__status {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #bbb;
  flex: 0 0 auto;
  margin-left: auto;
  position: relative;
}

.leave-item__status--approved {
  background-color: green;
}

.leave-item__status--denied {
  background-color: #d00;
}

.leave-item__status svg {
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
