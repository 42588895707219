<template>
  <ul class="tab-bar" v-if="tabs">
    <li class="tab-bar__item" v-for="tab in tabs" :key="tab.path">
      <router-link class="tab-bar__button" :to="tab.path">
        <font-awesome-icon v-if="tab.icon" :icon="tab.icon" />
        {{ tab.title }}
      </router-link>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    tabs: Array,
  },
};
</script>

<style scoped>
.tab-bar {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  border-bottom: 1px solid #eee;
}

.tab-bar__button {
  text-decoration: none;
  color: var(--text-color);
  padding: .875rem 1.125rem;
  line-height: 1;
  display: block;
  border-bottom: 2px solid transparent;
}

.tab-bar__button.router-link-exact-active {
  font-weight: 700;
  border-bottom-color: var(--primary-color);
}

.tab-bar__button [data-icon] {
  margin-right: .25rem;
}

@media (max-width: 699px) {
  .tab-bar__item {
    flex-grow: 1;
    text-align: center;
  }
}
</style>
