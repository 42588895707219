<template>
  <div class="time-entry h-100">
    <title-bar
      :leftbutton="{ text: 'Ververs', action: refreshEntries, icon: 'sync' }"
      title="Tijd registratie"
    />

    <time-entry-tab-bar />

    <form class="content-section" ref="form" action="" @submit="onSubmit">
      <laravel-errors
        v-if="Object.keys(errors).length"
        :errors="errors"
      />

      <div class="form-group">
        <label for="shift_id">Dienst</label>
        <Select2
          v-model="selectedShiftId"
          :options="shiftOptions"
          :settings="{
            dropdownCssClass: 'time-entry__dropdown',
            placeholder: 'Selecteer een dienst...',
            templateResult: templateResult,
          }"
          @select="onShiftSelect($event)"
        />

        <input type="hidden" name="shift_id" :value="selectedShiftId">
      </div>

      <div class="form-group time-entry__project-info">
        <div>
          <label for="project_name">Project naam</label>
          <input
            class="form-control"
            type="text"
            name="project_name"
            id="project_name"
            v-model="projectName"
          >
        </div>

        <div>
          <label for="project_name">Project nummer</label>
          <input
            class="form-control"
            type="text"
            name="project_number"
            id="project_number"
            v-model="projectNumber"
          >
        </div>
      </div>

      <div class="form-group" v-if="accountableForShift">
        <label for="user_id">Medewerker</label>
        <select class="select-control" name="user_id" id="user_id">
          <optgroup label="Medewerkers in dienst">
            <option v-for="employee in selectedShiftCrew" :key="employee.id" :value="employee.id">
              {{ employee.fullname }}
            </option>
          </optgroup>
          <optgroup label="Medewerkers buiten dienst">
            <option v-for="employee in contacts" :key="employee.id" :value="employee.id">
              {{ employee.name }}
            </option>
          </optgroup>
        </select>
      </div>

      <div class="form-group">
        <label for="start_date">Start datum/tijd</label>
        <datepicker
          v-model="selectedShiftStartTime"
          id="start_date"
          :min-date="minDate"
          :max-date="maxDate"
        />
      </div>

      <div class="form-group">
        <label for="end_date">Eind datum/tijd</label>
        <datepicker
          v-model="selectedShiftEndTime"
          id="end_date"
          :min-date="minDate"
          :max-date="maxDate"
        />
      </div>

      <div class="form-group">
        <label for="comment">Werkzaamheden</label>
        <textarea
          class="form-control"
          name="comment"
          id="comment"
          rows="2"
        ></textarea>
      </div>

      <div
        class="form-group color-danger"
        v-if="!isFormValid || timeEntryStoreLoadingState === 'loading'"
      >
        <div v-if="!validateDateRange">
          Een start- en eindtijd is niet ingevoerd, de starttijd ligt voor de eindtijd
          of de registratie is langer dan 20 uur.
        </div>
        <div v-else-if="!validateMissingFormFields">
          Een of meerdere velden zijn niet ingevoerd. Controleer of de start- en eindtijd/datum
          zijn ingevoerd.
        </div>
        <div v-else-if="timeEntryStoreLoadingState === 'loading'">
          We zijn de tijdsregistratie aan het versturen, een moment geduld...
        </div>
      </div>

      <div class="time-entry__submit form-group">
        <button
          type="button"
          class="btn btn--default time-entry__submit-more w-100"
          :disabled="timeEntryStoreLoadingState === 'loading' || !isFormValid"
          @click="(event) => onSubmit(event, 'another')"
        >
          {{
            timeEntryStoreLoadingState === 'loading'
              ? 'Een moment geduld...'
              : '+ Nog een'
          }}
        </button>

        <button
          type="button"
          class="btn btn--primary w-100"
          :disabled="timeEntryStoreLoadingState === 'loading' || !isFormValid"
          @click="(event) => onSubmit(event, 'new')"
        >
          {{
            timeEntryStoreLoadingState === 'loading'
              ? 'Een moment geduld...'
              : 'Uren registratie versturen'
          }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import $ from 'jquery';
import { mapActions, mapGetters } from 'vuex';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import formatISO from 'date-fns/formatISO';
import TitleBar from '../../components/TitleBar.vue';
import TimeEntryTabBar from './TimeEntryTabBar.vue';
import LaravelErrors from '../../components/LaravelErrors.vue';
import Datepicker from '../../components/Datepicker.vue';

export default {
  name: 'time-entry',

  components: {
    Datepicker,
    LaravelErrors,
    TimeEntryTabBar,
    TitleBar,
  },

  data() {
    const today = new Date();
    const minDate = new Date();
    minDate.setDate(minDate.getDate() - 60);

    return {
      errors: {},
      selectedShiftId: null,
      accountableForShift: false,
      selectedShiftStartTime: null,
      selectedShiftEndTime: null,
      customShift: false,
      minDate,
      maxDate: today,
      projectName: '',
      projectNumber: '',
    };
  },

  computed: {
    ...mapGetters([
      'timesheetShifts',
      'user',
      'timeEntryStoreLoadingState',
      'contacts',
    ]),

    selectedShift() {
      if (this.selectedShiftId === 'custom') {
        return null;
      }

      return this.timesheetShifts.filter((timesheetShift) => {
        return timesheetShift.id === this.selectedShiftId;
      })[0];
    },

    selectedShiftCrew() {
      if (typeof this.selectedShift === 'undefined' || typeof this.selectedShift.crew === 'undefined') {
        return [];
      }

      return this.selectedShift.crew;
    },

    shiftOptions() {
      const timesheets = this.timesheetShifts.map((shift) => {
        let parts = [shift.project_name, shift.project_identifier, shift.name];

        parts = parts.filter((part) => part);

        let startDate = parseISO(shift.start_datetime);
        let endDate = parseISO(shift.end_datetime);

        startDate = format(startDate, 'dd-MM-yyyy HH:mm');
        endDate = format(endDate, 'dd-MM-yyyy HH:mm');

        return {
          id: shift.id,
          text: parts.join(' - '),
          subline: `${startDate} &ndash; ${endDate}`,
        };
      });

      timesheets.unshift({
        id: 'custom',
        text: 'Nieuwe dienst',
      });

      return timesheets;
    },

    validateMissingFormFields() {
      if (!this.selectedShiftStartTime || !this.selectedShiftEndTime || !this.selectedShiftId) {
        return false;
      }

      if (!this.customShift && !this.selectedShiftId) {
        return false;
      }

      return true;
    },

    validateDateRange() {
      const start = new Date(this.selectedShiftStartTime);
      const end = new Date(this.selectedShiftEndTime);

      const startTime = start.getTime();
      const endTime = end.getTime();

      const startTimeMinutes = startTime / 1000 / 60;
      const endTimeMinutes = endTime / 1000 / 60;

      const maxMinuteDistance = 1200; // 20 hours

      const timeInMaxDistance = (endTimeMinutes - startTimeMinutes) < maxMinuteDistance;

      return (startTime < endTime) && timeInMaxDistance;
    },

    isFormValid() {
      if (!this.validateMissingFormFields) {
        return false;
      }

      if (!this.validateDateRange) {
        return false;
      }

      return true;
    },
  },

  methods: {
    ...mapActions([
      'fetchTimeEntries',
      'fetchUser',
      'storeTimeEntry',
    ]),

    refreshEntries() {
      this.fetchTimeEntries();
      this.fetchUser();
    },

    onSubmit(event, type) {
      const formData = new FormData(this.$refs.form);

      formData.append('custom_shift', this.customShift ? '1' : '0');
      formData.append('start_date', formatISO(this.selectedShiftStartTime));
      formData.append('end_date', formatISO(this.selectedShiftEndTime));

      if (this.customShift) {
        formData.delete('shift_id');
      }

      this.errors = {};

      this.storeTimeEntry(formData)
        .then(() => {
          if (type === 'new') {
            this.resetForm();
          }

          this.$toasted.show('Je tijd registratie is ontvangen en terug te vinden in het overzicht.', { type: 'success' }).goAway(5000);

          this.refreshEntries();
        })
        .catch((response) => {
          if (typeof response !== 'undefined' && typeof response.data !== 'undefined' && typeof response.data.errors !== 'undefined') {
            this.errors = response.data.errors;
          }

          this.$toasted.show('Probleem bij het opslaan van de tijdregistratie, controleer je gegevens en probeer het opnieuw', { type: 'error' }).goAway(2500);
        });
    },

    onShiftSelect(event) {
      this.customShift = false;

      if (event.id === 'custom') {
        this.customShift = true;
        this.checkAccountable();
        return;
      }

      this.selectedShiftId = parseInt(event.id, 10);

      this.checkAccountable();

      this.projectName = this.selectedShift.project_name;
      this.projectNumber = this.selectedShift.project_identifier;

      this.selectedShiftStartTime = parseISO(this.selectedShift.start_datetime);
      this.selectedShiftEndTime = parseISO(this.selectedShift.end_datetime);
    },

    checkAccountable() {
      if (!this.selectedShift) {
        this.accountableForShift = false;
        return;
      }

      const accountableUserId = this.selectedShift.accountable_user_id;

      // Check if this shift has an accountable user.
      if (typeof accountableUserId === 'undefined' || !accountableUserId) {
        this.accountableForShift = false;
        return;
      }

      // Check if the current authenticated user is the accountable user for this shift.
      // When accountable you can add time entries for other employees on the shift.
      if (accountableUserId === this.user.id) {
        this.accountableForShift = true;
      }
    },

    templateResult(item) {
      let html = `<span>${item.text}</span>`;

      if (item.subline) {
        html += `
          <br><span class="select-2-sub">${item.subline}</span>
        `;
      }

      return $(html);
    },

    resetForm() {
      this.errors = {};
      this.selectedShiftId = null;
      this.accountableForShift = false;
      this.selectedShiftStartTime = null;
      this.selectedShiftEndTime = null;
      this.customShift = false;
      this.projectName = '';
      this.projectNumber = '';
    },
  },

  watch: {
    $route: 'refreshEntries',
  },

  mounted() {
    this.refreshEntries();

    this.$store.dispatch('fetchContacts');
  },
};
</script>

<style>
.time-entry .content-section {
  max-height: calc(100% - 94px);
}

.time-entry .select2-container {
  font-size: 1rem;
}

.select-2-sub {
  color: #777;
  font-size: .875rem;
}

.time-entry .select2-container .select2-selection--single {
  height: 42px;
  border-color: #ddd;
}

.time-entry .select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 42px;
  color: #000;
  padding-left: .75rem;
  padding-right: .75rem;
}

.time-entry .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 42px;
  width: 36px;
}

.time-entry__dropdown,
.time-entry__dropdown input {
  font-family: var(--font);
}

.time-entry__dropdown .select2-search__field {
  padding: .75rem 1rem;
  font-size: 1rem;
}

.time-entry__dropdown .select2-results__option--highlighted[aria-selected] {
  background-color: var(--primary-color);
}

.time-entry__dropdown .select2-results>.select2-results__options {
  max-height: 320px;
}

.time-entry__dropdown .select2-results__option {
  padding: .625rem 1rem;
}

.time-entry .form-group {
  padding: .625rem;
}

.time-entry .form-group label {
  margin-bottom: .25rem;
}

.time-entry__project-info {
  display: flex;
}

.time-entry__project-info > *:first-child {
  max-width: 60%;
  flex: 0 0 60%;
  padding-right: .5rem;
}

.time-entry__project-info > *:last-child {
  max-width: 40%;
  flex: 0 0 40%;
}

.time-entry__submit {
  display: flex;
}

.time-entry__submit-more {
  flex-grow: 0;
  margin-right: .5rem;
  width: 9rem;
}

.color-danger {
  color: #dc7f0d;
}
</style>
