import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import Select2 from 'v-select2-component';

import App from './App.vue';
import router from './router';
import store from './store';
import config from './config';
import './registerServiceWorker';

import './icons';

Vue.config.productionTip = false;

if (config.sentryDsn) {
  Sentry.init({
    dsn: config.sentryDsn,
    integrations: [
      new Integrations.Vue({
        Vue,
        attachProps: true,
      }),
    ],
  });
}

Vue.component('Select2', Select2);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
