import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import UserStore from './store/UserStore';
import SafetyContactStore from './store/SafetyContactStore';
import ContactsStore from './store/ContactsStore';
import NewsStore from './store/NewsStore';
import PlanningStore from './store/PlanningStore';
import KilometerRegistrationStore from './store/KilometerRegistrationStore';
import SettingsStore from './store/SettingsStore';
import TimeEntryStore from './store/TimeEntryStore';
import VcaToolboxStore from './store/VcaToolboxStore';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  filter(/* mutation */) {
    return true;
  },
});

export default new Vuex.Store({
  modules: {
    user: UserStore,
    safetyContact: SafetyContactStore,
    contacts: ContactsStore,
    news: NewsStore,
    planning: PlanningStore,
    kilometerRegistration: KilometerRegistrationStore,
    settings: SettingsStore,
    timeEntry: TimeEntryStore,
    vcaToolbox: VcaToolboxStore,
  },

  plugins: [vuexLocal.plugin],
});
