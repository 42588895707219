<template>
  <div class="alert alert--danger" v-if="Object.keys(errors).length">
    <ul class="mt-0 mb-0 list-style-none p-0">
      <template v-for="errorGroup in errors">
        <li v-for="error in errorGroup" :key="error">
          {{ error }}
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    errors: Object,
  },
};
</script>
