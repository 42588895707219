<template>
  <div>
    <div
      :class="{
        'quiz__card': true,
        'is-active': slideIndex === activeSlide,
        'is-completed': slideIndex < activeSlide,
      }"
      v-for="(slide, slideIndex) in slides"
      :key="slide.id"
      :style="{ zIndex: slideIndex }"
    >
      <quiz-slide
        :slide="slide"
        :is-active="slideIndex === activeSlide"
      />

      <div class="quiz__card-actions">
        <button
          type="button"
          class="quiz__card-action btn btn--primary"
          v-if="slideIndex < (slides.length - 1)"
          @click="$emit('next-slide')"
        >
          Volgende slide <font-awesome-icon icon="chevron-right" class="ml-1" />
        </button>

        <button
          type="button"
          class="quiz__card-action btn btn--success"
          v-if="questionsAvailable && slideIndex >= (slides.length - 1)"
          @click="$emit('to-questions')"
        >
          <font-awesome-icon icon="check" class="mr-1" />
          Naar de vragen
        </button>

        <button
          type="button"
          class="quiz__card-action btn"
          v-if="slideIndex > 0"
          @click="$emit('previous-slide')"
        >
          <font-awesome-icon icon="chevron-left" class="mr-1" />
          Vorige slide
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import QuizSlide from './QuizSlide.vue';

export default {
  props: {
    activeSlide: Number,
    slides: Array,
  },

  components: {
    QuizSlide,
  },

  computed: {
    ...mapGetters([
      'activeVcaToolbox',
    ]),

    questionsAvailable() {
      return (
        this.activeVcaToolbox.is_online
        && !this.activeVcaToolbox.completed
      );
    },
  },
};
</script>
