<template>
  <div class="leave-registration h-100">
    <title-bar
      :left="{ text: 'Verlof', path: '/leave' }"
      title="Verlof aanvragen"
    />

    <form class="content-section" ref="form" action="">
      <div class="form-group">
        <label for="type">Type verlof</label>
        <select class="select-control" name="type" id="type" required>
          <option value="">Type verlof</option>
          <option
            v-for="(reasonValue, reasonKey) in leaveTypes"
            :value="reasonKey"
            :key="reasonKey"
          >
            {{ reasonValue }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <label for="start_at">Van</label>
        <input class="form-control" type="date" name="start_at" id="start_at" required>
      </div>

      <div class="form-group">
        <label for="end_at">Tot en met</label>
        <input class="form-control" type="date" name="end_at" id="end_at" required>
      </div>

      <div class="form-group">
        <label for="hours">Uren (optioneel)</label>
        <input class="form-control" type="number" name="hours" id="hours">
      </div>

      <div class="form-group">
        <label for="reason">Opmerking (optioneel)</label>
        <textarea class="form-control" name="reason" id="reason" rows="4"></textarea>
      </div>

      <div class="form-group">
        <button
          type="submit"
          class="btn btn--primary w-100"
          :disabled="formState === 'loading'"
        >
          {{ formState === 'loading' ? 'Een moment geduld...' : 'Verstuur verlof aanvraag' }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
/* global gtag */

import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import porta from 'porta';
import nl from 'porta/src/i18n/nl';
import leaveTypes from '../../leaveTypes';
import TitleBar from '../../components/TitleBar.vue';

export default {
  name: 'leave-registration',

  components: {
    TitleBar,
  },

  data() {
    return {
      leaveTypes,
      formState: 'idle',
    };
  },

  computed: {
    ...mapGetters([
      //
    ]),
  },

  methods: {
    ...mapActions([
      'postAbsence',
    ]),

    onSubmit() {
      const formData = new FormData(this.$refs.form);

      Vue.set(this, 'formState', 'loading');

      this.postAbsence(formData)
        .then(() => {
          Vue.set(this, 'formState', 'success');
          this.$router.push({ name: 'leave' });

          if (typeof gtag !== 'undefined') {
            gtag('event', 'submit', {
              event_category: 'Absence',
              event_label: '',
            });
          }
        })
        .catch(() => {
          Vue.set(this, 'formState', 'error');
        });
    },
  },

  mounted() {
    if (this.$refs.form) {
      porta({
        target: this.$refs.form,
        onValid: () => {
          this.onSubmit();

          return false;
        },
        messages: nl,
      });
    }
  },
};
</script>

<style scoped>
.leave-registration {
  height: 100%;
}
</style>

<style>
.porta-error-message {
  color: #d00;
  padding-top: 5px;
}
</style>
