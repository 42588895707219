<template>
  <div class="quiz-question">
    <h2 class="quiz__card-title" v-if="question.title">
      {{ question.title }}
    </h2>

    <div class="quiz__card-media" v-if="hasMedia">
      <img
        v-if="mediaFileType === 'image'"
        class="quiz__card-media-image"
        :src="`${mediaHost}${question.mediaItem.path}`"
        alt=""
      />
      <a
        v-if="mediaFileType === 'file'"
        :href="`${mediaHost}${question.mediaItem.path}`"
        class="btn btn--primary text-decoration-none"
      >
        Bekijk {{ question.mediaItem.original_filename
          ? question.mediaItem.original_filename.replace(/_|\-/, ' ')
          : question.mediaItem.path }}
      </a>
    </div>

    <div class="quiz__card-actions" v-if="!hasMultipleCorrectAnswers">
      <button
        v-for="option in question.options"
        :key="option.id"
        type="button"
        class="quiz__card-action quiz-question__answer btn"
        :data-wrong="answered.indexOf(option.id) >= 0 && !option.correct ? 1 : 0"
        :data-correct="answered.indexOf(option.id) >= 0 && option.correct ? 1 : 0"
        @click="(event) => onAnswer(event, option)"
        :disabled="locked"
      >
        <font-awesome-icon
          icon="times"
          class="mr-1"
          v-if="answered.indexOf(option.id) >= 0 && !option.correct"
        />
        <font-awesome-icon
          icon="check"
          class="mr-1"
          v-if="answered.indexOf(option.id) >= 0 && option.correct"
        />
        {{ option.title }}
      </button>
    </div>

    <div class="quiz__card-actions" v-if="hasMultipleCorrectAnswers">
      <div class="mb-3">
        <div
          class="mb-2"
          v-for="option in question.options"
          :key="option.id"
        >
          <label class="quiz__card-checkbox" :for="`answer-${option.id}`">
            <input
              type="checkbox"
              :id="`answer-${option.id}`"
              :value="option.id"
              :checked="selectedOptions.indexOf(option.id) >= 0"
              @change="(event) => onChangeSelectedOption(option.id, event.currentTarget.checked)"
            >
            {{ option.title }}
          </label>
        </div>
      </div>

      <div v-if="multipleOptionsIncorrect" class="alert alert--danger mb-3">
        Een of meerdere antwoorden zijn onjuist.
      </div>

      <button
        class="quiz__card-action quiz-question__answer btn btn--primary"
        @click="(event) => onAnswerMultiple(event)"
        :disabled="locked"
      >
        Verstuur
      </button>
    </div>
  </div>
</template>

<script>
import config from '../../config';

export default {
  props: {
    question: Object,
  },

  data() {
    return {
      answered: [],
      locked: false,
      mediaHost: config.mediaBasePath,
      selectedOptions: [],
      multipleOptionsIncorrect: false,
    };
  },

  methods: {
    onAnswer(event, option) {
      this.answered.push(option.id);

      if (option.correct) {
        this.locked = true;
      }

      this.$emit(option.correct ? 'answer-correct' : 'answer-wrong', option.title);
    },

    onAnswerMultiple() {
      let isValid = true;

      const selectedOptions = this.question.options.filter((option) => {
        const isSelected = this.selectedOptions.indexOf(option.id) >= 0;

        // Option should be checked by the user
        if (option.correct && !isSelected) {
          isValid = false;
        }

        // Option should not be checked by the user
        if (!option.correct && isSelected) {
          isValid = false;
        }

        return isSelected;
      });

      const selectedOptionsText = selectedOptions.map((option) => option.title).join(', ');

      if (isValid) {
        this.locked = true;
      }

      this.$emit(isValid ? 'answer-correct' : 'answer-wrong', selectedOptionsText);

      this.multipleOptionsIncorrect = !isValid;
    },

    onChangeSelectedOption(optionId, checkedState) {
      if (!checkedState) {
        const selectedOptions = this.selectedOptions.filter((option) => {
          return option !== optionId;
        });

        this.selectedOptions = selectedOptions;

        return;
      }

      this.selectedOptions.push(optionId);
    },
  },

  computed: {
    hasMedia() {
      return this.question.mediaItem && this.question.mediaItem.path;
    },

    mediaFileType() {
      if (!this.hasMedia) {
        return false;
      }

      return this.question.mediaItem.type;
    },

    hasMultipleCorrectAnswers() {
      if (!this.question || !this.question.options || !Array.isArray(this.question.options)) {
        return false;
      }

      return this.question.options.filter((answer) => {
        return answer.correct;
      }).length > 1;
    },
  },
};
</script>

<style scoped>
.quiz-question {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.quiz__card-title {
  margin-bottom: 15px;
}

.quiz-question__answer[disabled] {
  background-color: #eee;
  color: #aaa;
}

.quiz-question__answer[data-correct='1'] {
  background-color: var(--success-color);
  color: #fff;
}

.quiz-question__answer[data-wrong='1'] {
  background-color: var(--danger-color);
  color: #fff;
}

.quiz__card-checkbox {
  padding-left: 1.5rem;
  position: relative;
  display: block;
}

.quiz__card-checkbox input {
  position: absolute;
  top: 0;
  left: 0;
}
</style>
