<template>
  <li class="time-entry-item">
    <div class="time-entry-item__title mb-1">
      <span class="sr-only">Gemaakt door</span>
      {{ timesheet.user_fullname }}
    </div>

    <!--
      When there is a shift ID, there is a relation to a project in the database.
      In that case we show the related project name.
    -->
    <div class="time-entry-item__project mb-2" v-if="timesheet.shift_id">
      <strong>Project</strong>: {{ timesheet.project_name }}
      <span v-if="timesheet.project_identifier">({{ timesheet.project_identifier }})</span>
      / <strong>Dienst</strong>: {{ timesheet.shift_name }}
      <span v-if="timesheet.shift_comment">({{ timesheet.shift_comment }})</span>
    </div>

    <div
      class="time-entry-item__project mb-2"
      v-if="timesheet.custom_project_name || timesheet.custom_project_number"
    >
      <strong>Ingevoerd project</strong>: {{ timesheet.project_name }}
      <span v-if="timesheet.custom_project_number">({{ timesheet.custom_project_number }})</span>
    </div>

    <div class="mb-2" v-if="!extendedView && timesheet.comment">
      {{ timesheet.comment }}
    </div>

    <div class="time-entry-item__preview" v-if="!extendedView">
      <div>
        <strong>Van</strong><br>
        {{ formattedStartDateTime }}
      </div>

      <div>
        <strong>Tot</strong><br>
        {{ formattedEndDateTime }}
      </div>

      <div>
        <strong>Uren</strong><br>
        {{ timesheet.hours }}
      </div>
    </div>

    <div v-show="extendedView">
      <div class="mb-3">
        <strong class="d-block mb-1">Datum</strong>
        <div class="time-entry-item__duration">
          <div class="time-entry-item__from">
            <label :for="`start_date_${timesheet.id}`">
              Van
            </label>
            <datepicker
              :id="`start_date_${timesheet.id}`"
              v-model="startDateTime"
            />
          </div>

          <div class="time-entry-item__between">
            t/m
          </div>

          <div class="time-entry-item__to">
            <label :for="`end_date_${timesheet.id}`">
              Tot
            </label>
            <datepicker
              :id="`end_date_${timesheet.id}`"
              v-model="endDateTime"
            />
          </div>
        </div>
      </div>

      <div class="mb-3">
        <label class="font-weight-bold mb-1 d-block" for="hours">Uren</label>
        <input
          class="form-control"
          type="number"
          step="1"
          name="hours"
          id="hours"
          v-model="timesheet.hours"
        >
      </div>

      <div>
        <label class="font-weight-bold mb-1 d-block" for="comment">Opmerking</label>
        <textarea
          class="form-control"
          name="comment"
          id="comment"
          v-model="timesheet.comment"
        ></textarea>
      </div>
    </div>

    <div class="time-entry__actions">
      <button
        class="time-entry-item__action btn btn--success"
        type="button"
        @click="(event) => approveEntry(event, timesheet.id, true)"
      >
        <font-awesome-icon icon="check" class="mr-1" /> Goedkeuren
      </button>

      <button
        class="time-entry-item__action btn btn--danger"
        type="button"
        @click="(event) => approveEntry(event, timesheet.id, false)"
      >
        Afwijzen
      </button>

      <button
        class="time-entry-item__action btn btn--default"
        type="button"
        @click="onToggleExtendedView"
      >
        {{ extendedView ? 'Sluit' : 'Wijzig' }}
      </button>
    </div>
  </li>
</template>

<script>
import { mapActions } from 'vuex';
import parseISO from 'date-fns/parseISO';
import format from '@/helpers/format';
import Datepicker from '../../components/Datepicker.vue';

export default {
  name: 'time-entry-approval-row',

  props: {
    timesheet: Object,
  },

  components: {
    Datepicker,
    //
  },

  data() {
    const startDateTime = parseISO(`${this.timesheet.start_date} ${this.timesheet.start_time}`);
    const endDateTime = parseISO(`${this.timesheet.end_date} ${this.timesheet.end_time}`);

    return {
      startDateTime,
      endDateTime,
      formattedStartDateTime: format(startDateTime, 'dd-MM-yyyy HH:mm'),
      formattedEndDateTime: format(endDateTime, 'dd-MM-yyyy HH:mm'),
      extendedView: false,
    };
  },

  methods: {
    ...mapActions([
      'approveTimeEntry',
      'disapproveTimeEntry',
    ]),

    approveEntry(event, timesheetId, approved = true) {
      const formData = new FormData();

      formData.append('approved', approved ? '1' : '0');
      formData.append('timesheet_id', timesheetId);
      formData.append('start_date', format(this.startDateTime, 'yyyy-MM-ddTHH:mm'));
      formData.append('end_date', format(this.endDateTime, 'yyyy-MM-ddTHH:mm'));
      formData.append('hours', this.timesheet.hours);
      formData.append('comment', this.timesheet.comment);

      this.approveTimeEntry(formData)
        .then(() => {
          const message = approved
            ? 'Tijd registratie is goedgekeurd'
            : 'Tijd registratie is afgewezen';

          this.$toasted.show(message, { type: 'success' }).goAway(2500);
          this.$emit('approved');
        })
        .catch(() => {
          const message = approved
            ? 'Probleem bij het goedkeuren, probeer het later opnieuw'
            : 'Probleem bij het afwijzen, probeer het later opnieuw';

          this.$toasted.show(message, { type: 'error' }).goAway(2500);
        });
    },

    onToggleExtendedView() {
      this.extendedView = !this.extendedView;
    },
  },
};
</script>

<style scoped>
.time-entry-item {
  padding: .875rem;
  border-bottom: 1px solid #e3e3e3;
  border-top: 1px solid transparent;
  line-height: 1.4;
  display: flex;
  flex-direction: column;
}

.time-entry-item__from,
.time-entry-item__to {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  margin-bottom: .375rem;
}

.time-entry-item__title {
  font-size: 1rem;
  font-weight: 700;
}

.time-entry-item__duration {
  margin-bottom: -.375rem;
}

.time-entry-item__duration label {
  min-width: 3rem;
}

.time-entry-item__duration .form-control {
  font-size: .875rem;
  padding: .625rem .875rem;
}

.time-entry-item__between {
  display: none;
}

.time-entry__actions {
  display: flex;
  width: 100%;
}

.time-entry-item__action {
  margin-top: .75rem;
  margin-right: .5rem;
  flex-grow: 1;
}

.time-entry-item__action:last-child {
  margin-right: 0;
}

.time-entry-item__preview {
  display: flex;
  width: 100%;
}

.time-entry-item__preview div {
  padding-right: 1rem;
  flex-grow: 1;
}

@media (min-width: 370px) {
  .time-entry-item__duration {
    display: flex;
    margin-bottom: 0;
  }

  .time-entry-item__from,
  .time-entry-item__to {
    margin-bottom: 0;
  }

  .time-entry-item__between {
    width: 40px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .time-entry-item__duration label {
    opacity: 0;
    position: absolute;
    height: 0;
    width: 0;
    overflow: hidden;
  }

  .time-entry-item__from > span,
  .time-entry-item__to > span {
    display: block;
    width: 100%;
  }
}
</style>
