import ApiRequest from '../helpers/ApiRequest';

const VcaToolboxStore = {
  state: {
    vcaToolboxes: [],
    activeVcaToolboxId: null,
    vcaToolboxesLoadingState: 'idle',
    vcaToolboxStoreLoadingState: 'idle',
  },

  getters: {
    vcaToolboxes: (state) => state.vcaToolboxes,
    vcaToolboxesLoadingState: (state) => state.vcaToolboxesLoadingState,
    vcaToolboxStoreLoadingState: (state) => state.vcaToolboxStoreLoadingState,

    activeVcaToolbox(state, getters) {
      if (!state.activeVcaToolboxId) {
        return null;
      }

      const filteredToolboxes = getters.vcaToolboxes.filter((toolbox) => {
        return parseInt(toolbox.id, 10) === state.activeVcaToolboxId;
      });

      return filteredToolboxes.length ? filteredToolboxes[0] : null;
    },
  },

  mutations: {
    setVcaToolboxes(state, vcaToolboxes) {
      state.vcaToolboxes = vcaToolboxes;
    },

    setVcaToolboxesLoadingState(state, loadingState) {
      state.vcaToolboxesLoadingState = loadingState;
    },

    setVcaToolboxStoreLoadingState(state, loadingState) {
      state.vcaToolboxStoreLoadingState = loadingState;
    },

    setActiveVcaToolboxId(state, id) {
      state.activeVcaToolboxId = parseInt(id, 10);
    },
  },

  actions: {
    async fetchVcaToolboxes({ commit, rootState }) {
      commit('setVcaToolboxesLoadingState', 'loading');

      try {
        const res = await ApiRequest.authorizedGet('/vca-tests', rootState.user.currentJWT);

        const { data } = res;

        commit('setVcaToolboxes', data);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
        commit('setVcaToolboxesLoadingState', 'error');
        return;
      }

      commit('setVcaToolboxesLoadingState', 'idle');
    },

    async storeVcaToolbox({ commit, rootState }, data) {
      commit('setVcaToolboxStoreLoadingState', 'loading');

      let promise;

      try {
        promise = await ApiRequest.authorizedPost('/vca-tests', data, rootState.user.currentJWT);

        commit('setVcaToolboxStoreLoadingState', 'idle');
      } catch (error) {
        commit('setVcaToolboxStoreLoadingState', 'error');
        return Promise.reject(error);
      }

      return promise;
    },
  },
};

export default VcaToolboxStore;
