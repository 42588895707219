<template>
  <div :class="`app is-${deviceType}`">
    <navigation />

    <div class="router-wrapper">
      <router-view class="router" />
    </div>

    <intro-modal modal-key="intro" />
  </div>
</template>

<script>
/* global gtag */

import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import IntroModal from './components/IntroModal.vue';
import Navigation from './components/Navigation.vue';

export default {
  components: {
    IntroModal,
    Navigation,
  },

  data() {
    return {
      deviceType: '',
    };
  },

  created() {
    axios.interceptors.response.use((response) => (response), (error) => {
      // Do something with response error
      if (typeof error.response !== 'undefined' && error.response.status === 401) {
        // eslint-disable-next-line no-console
        console.log('Unauthorized, logging out...');
        this.$router.push({ name: 'logout' });
      }

      return Promise.reject(error.response);
    });
  },

  mounted() {
    this.fetchContacts();
    this.fetchUser();

    document.addEventListener('swUpdated', this.showRefreshUI);

    this.deviceType = (
      navigator.userAgent.indexOf('iPad') >= 0
      || navigator.userAgent.indexOf('iPhone') >= 0
      || navigator.userAgent.indexOf('iPod') >= 0
    ) ? 'ios' : 'other';

    if (!this.hasSeenIntro) {
      this.$modal.show('intro');
      this.markIntroSeen();
    }
  },

  beforeDestroy() {
    document.removeEventListener('swUpdated', this.showRefreshUI);
  },

  methods: {
    ...mapActions([
      'fetchContacts',
      'fetchUser',
      'markIntroSeen',
    ]),

    showRefreshUI() {
      this.$toasted.show('Er is een nieuwe versie van de app', {
        containerClassName: 'app-update',
        action: {
          text: 'Verversen',
          onClick: (toastEvent, toastObject) => {
            window.location.reload(true);
            toastObject.goAway(0);
          },
        },
      });
    },
  },

  computed: {
    ...mapGetters([
      'hasSeenIntro',
    ]),
  },

  watch: {
    $route(to) {
      if (typeof gtag !== 'undefined') {
        gtag('config', window.ua, { page_path: to.path });
      }
    },
  },
};
</script>

<style>
:root {
  --font: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Work Sans, Helvetica, sans-serif;
  --primary-color: #003c7f;
  --primary-color-shade: #003269;
  --text-color: #003c7f;
  --text-color-inverse: #fff;
  --title-bar-background-color: #003c7f;
  --title-bar-color: #fff;
  --success-color: #6ab04c;
  --danger-color: #eb4d4b;
  --safe-area-inset-top: env(safe-area-inset-top);
  --safe-area-inset-bottom: env(safe-area-inset-bottom);
  --safe-area-inset-left: env(safe-area-inset-left);
  --safe-area-inset-right: env(safe-area-inset-right);
}

html,
body {
  margin: 0;
  line-height: 1.4;
  font-family: var(--font);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

body {
  overflow: hidden;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.d-block {
  display: block;
}

.d-flex {
  display: flex;
}

.text-decoration-none {
  text-decoration: none;
}

.font-weight-bold {
  font-weight: 700;
}

.mb-0 { margin-bottom: 0 !important; }
.mb-1 { margin-bottom: .5rem !important; }
.mb-2 { margin-bottom: .75rem !important; }
.mb-3 { margin-bottom: 1rem !important; }

.mt-0 { margin-top: 0 !important; }
.mt-1 { margin-top: .5rem !important; }
.mt-2 { margin-top: .75rem !important; }
.mt-3 { margin-top: 1rem !important; }

.mr-0 { margin-right: 0 !important; }
.mr-1 { margin-right: .5rem !important; }
.mr-2 { margin-right: .75rem !important; }
.mr-3 { margin-right: 1rem !important; }

.ml-0 { margin-left: 0 !important; }
.ml-1 { margin-left: .5rem !important; }
.ml-2 { margin-left: .75rem !important; }
.ml-3 { margin-left: 1rem !important; }

.p-0 { padding: 0 !important; }
.p-2 { padding: .75rem !important; }
.p-3 { padding: 1rem !important; }

.ml-auto {
  margin-left: auto;
}

.list-style-none {
  list-style: none;
}

.app {
  font-family: var(--font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: .875rem;
  color: var(--text-color);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-bottom: 3rem;
  padding-bottom: calc(3rem + var(--safe-area-inset-bottom));
}

.toasted-container.bottom-left.app-update {
  bottom: 48px;
}

.toasted-container .toasted {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  line-height: 1.5 !important;
}

.router-wrapper {
  height: 100%;
  background: #22399f url(/img/bg.jpg) no-repeat center center;
  background-size: 410px auto;
}

.router {
  height: 100%;
  background-color: #fff;
  position: relative;
}

.content-section {
  height: calc(100% - 48px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.list-item {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: .875rem;
  padding: .75rem 1rem;
  border: 0;
  color: var(--text-color);
  text-decoration: none;
  border-bottom: 1px solid #eee;
  overflow: hidden;
}

.form-control,
.select-control {
  display: block;
  width: 100%;
  font-size: 1rem;
  padding: .5rem .75rem;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: .25rem;
  font-family: var(--font);
  height: 2.625rem;
  appearance: none;
}

.form-control[readonly],
.select-control[readonly] {
  background-color: #f6f6f6;
  color: #555;
}

.form-control:focus,
.select-control:focus {
  outline: none;
  border-color: var(--primary-color);
}

textarea.form-control {
  height: auto;
}

.form-group {
  padding: .75rem;
  border-bottom: 1px solid #eee;
}

.form-group label {
  margin-bottom: .5rem;
  display: block;
}

.alert {
  padding: .875rem;
  color: #fff;
}

.alert--danger {
  background-color: var(--danger-color);
}

.alert--success {
  background-color: var(--success-color);
}

.btn {
  display: inline-block;
  vertical-align: top;
  padding: .75rem;
  border-radius: .25rem;
  font-size: 1rem;
  border: 0;
  user-select: none;
  appearance: none;
}

.btn:focus {
  outline: none;
}

.btn:focus-visible {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, .1);
}

.btn--primary {
  background-color: var(--primary-color);
  color: #fff;
}

.btn--primary:focus-visible {
  box-shadow: 0 0 0 3px rgba(0, 60, 127, .2);
}

.btn--muted {
  background-color: #ddd;
  color: #333;
}

.btn--success {
  background-color: var(--success-color);
  color: #fff;
}

.btn--success:focus-visible {
  box-shadow: 0 0 0 3px rgba(106, 176, 76, .2);
}

.btn--danger {
  background-color: var(--danger-color);
  color: #fff;
}

.btn--danger:focus-visible {
  box-shadow: 0 0 0 3px rgb(235, 77, 75, .2);
}

.btn--full {
  width: 100%;
}

.btn--reset {
  background-color: transparent;
  border: 0;
  padding: 0;
}

.btn[disabled] {
  background-color: #bbb;
}
</style>

<style scoped>
@media (min-width: 700px) {
  .app {
    padding-bottom: 0;
    padding-left: 220px; /* Space for navigation */
  }
}

@media (min-width: 1200px) {
  .app {
    padding-left: 300px; /* Space for navigation */
  }
}
</style>
