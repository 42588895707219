import axios from 'axios';
import config from '../config';
import ApiRequest from '../helpers/ApiRequest';

const UserModule = {
  state: {
    currentJWT: '',
    user: null,
    absences: null,
  },

  getters: {
    jwt: (state) => state.currentJWT,
    jwtData: (state, getters) => {
      if (!state.currentJWT) {
        return null;
      }

      return JSON.parse(atob(getters.jwt.split('.')[1]));
    },

    jwtSubject: (state, getters) => {
      if (!getters.jwtData) {
        return null;
      }

      return getters.jwtData.sub;
    },

    jwtIssuer: (state, getters) => {
      if (!getters.jwtData) {
        return null;
      }

      return getters.jwtData.iss;
    },

    isLoggedIn: (state, getters) => !!getters.jwt,

    user(state) {
      return state.user;
    },

    absences(state) {
      return state.absences;
    },

    settings(state, getters) {
      if (getters.user) {
        return getters.user.settings;
      }

      return null;
    },

    activeVehicle(state) {
      // Check if a user has been loaded
      if (typeof state.user === 'undefined' || !state.user) {
        return null;
      }

      // Check if the user has a active vehicle
      if (typeof state.user.active_vehicle === 'undefined' || !state.user.active_vehicle) {
        return null;
      }

      return state.user.active_vehicle;
    },
  },

  mutations: {
    setJWT(state, jwt) {
      // When this updates, the getters and anything bound to them updates as well.
      state.currentJWT = jwt;
    },

    setUser(state, user) {
      state.user = user;
    },

    setAbsences(state, absences) {
      state.absences = absences;
    },
  },

  actions: {
    async fetchAbsences({ getters, commit }) {
      const res = await ApiRequest.authorizedGet('/absences', getters.jwt);

      commit('setAbsences', res.data);
    },

    postAbsence({ getters, state }, formData) {
      formData.append('user_id', getters.jwtSubject);

      return ApiRequest.authorizedPost('/absences', formData, state.currentJWT);
    },

    async fetchJWT({ commit }, { email, password }) {
      // Perform the HTTP request.
      const res = await axios.post(`${config.apiUrl}/auth/login`, {
        email,
        password,
      }, {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json',
        },
      });

      // Calls the mutation defined to update the state's JWT.
      commit('setJWT', res.data.access_token);

      this.dispatch('fetchUser');
    },

    fetchForgotPassword(store, { email }) {
      return ApiRequest.post('/auth/forgot-password', {
        email,
      });
    },

    async fetchUser({ commit, state }) {
      if (!state.currentJWT) {
        return;
      }

      const res = await ApiRequest.authorizedPost('/auth/me', null, state.currentJWT);

      commit('setUser', res.data);
    },

    unsetJWT({ commit }) {
      commit('setJWT', '');
    },
  },
};

export default UserModule;
