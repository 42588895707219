<template>
  <div class="dashboard">
    <h1>Uitloggen</h1>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'logout',

  components: {
    //
  },

  methods: {
    ...mapActions([
      'unsetJWT',
    ]),
  },

  mounted() {
    this.unsetJWT();
    this.$router.push({ name: 'dashboard' });
  },
};
</script>
