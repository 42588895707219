<template>
  <div class="km-registration h-100">
    <title-bar
      :left="{ text: 'Alles', path: '/kilometer-registrations' }"
      title="Registratie bewerken"
    />

    <form class="content-section" ref="form" action="">
      <div v-if="registration">
        <div class="form-group">
          <label for="start_mileage">Begin kilometerstand</label>
          <input
            class="form-control"
            type="number"
            name="start_mileage"
            id="start_mileage"
            :value="registration.start_mileage"
            required
          >
        </div>

        <div class="form-group">
          <label for="end_mileage">Eind kilometerstand</label>
          <input
            class="form-control"
            type="number"
            name="end_mileage"
            id="end_mileage"
            :value="registration.end_mileage"
            required
          >
        </div>

        <div class="form-group">
          <label for="from">Van adres</label>
          <input
            class="form-control"
            type="text"
            name="from"
            id="from"
            :value="registration.start_location"
            required
          >
        </div>

        <div class="form-group">
          <label for="to">Naar adres</label>
          <input
            class="form-control"
            type="text"
            name="to"
            id="to"
            :value="registration.end_location"
            required
          >
        </div>

        <div class="form-group">
          <label for="date">Datum</label>
          <input
            class="form-control"
            type="date"
            name="date"
            id="date"
            :value="date"
            required
          >
        </div>

        <div class="form-group">
          <label for="type">Type</label>
          <select
            class="select-control"
            name="type"
            id="type"
            :value="registration.type"
          >
            <option value="business">Zakelijk</option>
            <option value="private">Privé</option>
          </select>
        </div>

        <div class="alert alert--danger" v-if="formState === 'error'">
          Er ging iets mis bij het opslaan van je kilometerregistratie. Controleer je gegevens
          en probeer het opnieuw.
        </div>

        <div class="form-group">
          <button
            type="submit"
            class="btn btn--primary w-100"
            :disabled="formState === 'loading'"
          >
            {{ formState === 'loading' ? 'Een moment...' : 'Registratie opslaan' }}
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import porta from 'porta';
import nl from 'porta/src/i18n/nl';
import { parseISO, format } from 'date-fns';
import TitleBar from '../../components/TitleBar.vue';

export default {
  name: 'kilometer-registration-edit',

  components: {
    TitleBar,
  },

  data() {
    return {
      formState: 'idle',
      registration: null,
    };
  },

  computed: {
    ...mapGetters([
      'activeVehicle',
      'getRegistrationById',
    ]),

    date() {
      if (!this.registration) {
        return null;
      }

      return format(parseISO(this.registration.created_at), 'yyyy-MM-dd');
    },
  },

  methods: {
    ...mapActions([
      'putKilometerRegistration',
      'fetchUser',
      'fetchKilometerRegistrations',
    ]),

    onSubmit() {
      const formData = new FormData(this.$refs.form);

      Vue.set(this, 'formState', 'loading');

      this.putKilometerRegistration([this.registration.id, formData])
        .then(() => {
          Vue.set(this, 'formState', 'success');
          this.fetchUser();
          this.fetchKilometerRegistrations();
          this.$toasted.show('Kilometer registratie is aangepast', { type: 'success' }).goAway(2000);
          this.$router.push({ name: 'kilometer-registrations' });
        })
        .catch(() => {
          Vue.set(this, 'formState', 'error');
        });
    },
  },

  mounted() {
    const { id } = this.$route.params;

    this.registration = this.getRegistrationById(id);

    if (this.$refs.form) {
      porta({
        target: this.$refs.form,
        onValid: () => {
          this.onSubmit();

          return false;
        },
        messages: nl,
      });
    }
  },
};
</script>

<style scoped>
</style>

<style>
.porta-error-message {
  color: #d00;
  padding-top: 5px;
}
</style>
