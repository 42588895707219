<template>
  <div
    class="loader"
    :style="{
      opacity: isVisible ? 1 : 0,
      visibility: isVisible ? 'visible' : 'hidden',
    }"
  >
    <div class="loader__image"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isVisible: true,
    };
  },

  mounted() {
    setTimeout(() => {
      this.isVisible = false;
    }, 1500);
  },
};
</script>

<style scoped>
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: #141414;
  transition: opacity .5s ease-out, visibility .5s ease-out;
}

.loader__image {
  background: url('/img/sticker-inner.png') center center no-repeat;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader__image,
.loader__image:after {
  position: absolute;
  width: 200px;
  height: 200px;
  background-size: 100% auto;
}

.loader__image:after {
  content: '';
  background: url('/img/sticker-ring.png') center center no-repeat;
  top: 0;
  left: 0;
  background-size: 100% auto;
  animation: spin 10s infinite linear;
}

@media (min-width: 768px) {
  .loader__image,
  .loader__image:after {
    width: 300px;
    height: 300px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(359deg);
  }

  100% {
    transform: rotate(0deg);
  }
}
</style>
