<template>
  <div class="dashboard">
    <loader />

    <title-bar
      title="Dashboard"
      :left="isLoggedIn ? { text: 'Instellingen', path: 'settings' } : {}"
      :right="isLoggedIn ? { text: 'Uitloggen', path: 'logout' } : {}"
    ></title-bar>

    <div class="content-section">
      <greeter />

      <div class="dashboard__buttons">
        <router-link class="dashboard__button" to="/kilometer-registrations" v-if="isLoggedIn">
          <font-awesome-icon icon="car" />
          Kilometer registratie
        </router-link>

        <router-link class="dashboard__button" to="/leave" v-if="isLoggedIn">
          <font-awesome-icon icon="plane" />
          Verlof
        </router-link>

        <router-link class="dashboard__button" to="/time-entry" v-if="isLoggedIn">
          <font-awesome-icon icon="user-clock" />
          Uren registratie
        </router-link>

        <router-link class="dashboard__button" to="/safety-contact">
          <font-awesome-icon icon="exclamation-triangle" />
          Veiligheidsmelding doen
        </router-link>

        <router-link class="dashboard__button" to="/news">
          <font-awesome-icon icon="newspaper" />
          Nieuws
        </router-link>

        <router-link class="dashboard__button" to="/vca-toolbox" v-if="isLoggedIn">
          <font-awesome-icon icon="hard-hat" />
          Toolbox
        </router-link>

        <router-link class="dashboard__button"  to="/planning" v-if="isLoggedIn">
          <font-awesome-icon icon="calendar" />
          Planning
        </router-link>

        <router-link class="dashboard__button"  to="/contacts" v-if="isLoggedIn">
          <font-awesome-icon icon="users" />
          Contacten
        </router-link>

        <a
          class="dashboard__button"
          :href="sheetUrl"
          target="_blank"
          @click="onSafetySheetClick"
          v-if="isLoggedIn && sheetUrl"
        >
          <font-awesome-icon icon="hard-hat" />
          Veiligheids-
          informatieblad
        </a>

        <div class="dashboard__button">
          <div>
            <strong>Arbodienst Richting</strong>
            <br>
            <a href="tel:0765230146">076-5230146</a>
            <br>
            <a href="mailto:info@richting.nl">info@richting.nl</a>
          </div>
        </div>
      </div>

      <div class="dashboard__buttons-wrap d-flex">
        <router-link
          class="dashboard__login-button"
          v-if="!isLoggedIn"
          to="/login"
        >
          Inloggen
        </router-link>
      </div>

      <img
        class="dashboard__kernwaarden"
        src="/img/sticker.png"
        width="512"
        height="513"
        alt="De Wilde kernwaarden"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Greeter from '../components/Greeter.vue';
import TitleBar from '../components/TitleBar.vue';
import Loader from '../components/Loader.vue';

export default {
  name: 'dashboard',

  components: {
    TitleBar,
    Greeter,
    Loader,
  },

  computed: {
    ...mapGetters([
      'isLoggedIn',
      'settings',
    ]),

    sheetUrl() {
      if (this.isLoggedIn && this.settings) {
        return this.settings.safety_sheet;
      }

      return null;
    },
  },

  methods: {
    onSafetySheetClick(event) {
      event.preventDefault();
      window.location = event.target.href;
    },
  },
};
</script>

<style scoped>
.dashboard__login-button {
  background-color: var(--primary-color);
  color: #fff;
  padding: .875rem 1.125rem;
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
  user-select: none;
  border-radius: .25rem;
  margin: 1rem;
  align-self: flex-start;
}

.dashboard__login-button--logout {
  background-color: #ddd;
  color: #333;
}

.dashboard__buttons {
  clear: both;
  display: flex;
  flex-wrap: wrap;
}

.dashboard__button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 0 0 50%;
  max-width: 50%;
  padding: .875rem;
  text-align: center;
  height: 80px;
  line-height: 1.2;
  font-size: .75rem;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  color: #333;
  text-decoration: none;
}

.dashboard__button:nth-child(2n) {
  border-right: 0;
}

.dashboard__button svg {
  display: block;
  margin-bottom: .5rem;
  margin-left: auto;
  margin-right: auto;
  width: 1.5rem;
  height: 1.5rem;
  color: #999;
}

.dashboard__contact {
  padding: 10px 20px 10px 10px;
  color: #777;
  font-size: .8125rem;
}

.dashboard__contact a {
  color: #777;
  display: inline-block;
  margin-top: .125rem;
}

.dashboard__kernwaarden {
  pointer-events: none;
  max-width: 12rem;
  display: block;
  height: auto;
  margin: 1rem auto;
}

@media (min-height: 720px) {
  .dashboard__buttons-wrap {
    margin-top: 10px;
  }
}
</style>
