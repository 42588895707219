import ApiRequest from '../helpers/ApiRequest';
import isTimestampExpired from '../helpers/IsTimestampExpired';

const ContactsStore = {
  state: {
    contacts: null,
    contactsFetchDate: null,
  },

  getters: {
    contacts(state) {
      return state.contacts;
    },
  },

  mutations: {
    setContacts(state, contacts) {
      state.contacts = contacts;
    },

    setContactsFetchDate(state, timestamp) {
      state.contactsFetchDate = timestamp;
    },
  },

  actions: {
    async fetchContacts({ commit, state, rootState }) {
      const now = new Date().getTime();
      const cacheTime = 20 * 60 * 1000; // 20 minutes

      if (
        state.contacts
        && state.contactsFetchDate
        && !isTimestampExpired(state.contactsFetchDate, cacheTime)
      ) {
        return;
      }

      const res = await ApiRequest.authorizedGet('/contacts', rootState.user.currentJWT);

      commit('setContacts', res.data);
      commit('setContactsFetchDate', now);
    },
  },
};

export default ContactsStore;
