export default () => {
  let greeting = 'Goedemorgen';

  if (new Date().getHours() >= 18) {
    greeting = 'Goedenavond';
  } else if (new Date().getHours() >= 12) {
    greeting = 'Goedemiddag';
  }

  return greeting;
};
