<template>
  <div class="km-registrations h-100">
    <title-bar
      :leftbutton="{ text: 'Ververs', action: refreshKilometerRegistrations }"
      :right="{ text: 'Nieuw', path: '/kilometer-registration' }"
      title="Kilometer registraties"
    />

    <div class="registrations__meta" v-if="activeVehicle">
      <strong>Huidig voertuig:</strong>
      <span v-if="activeVehicle.description">
        {{ activeVehicle.description }} ({{ activeVehicle.registration }})
      </span>
      <span v-else>
        {{ activeVehicle.registration }}
      </span>
    </div>

    <virtual-list class="registrations__list" :size="56" :remain="25">
      <router-link
        v-for="registration in registrationsList"
        :key="registration.id"
        :to="`/kilometer-registration/edit/${registration.id}`"
        class="registration-item list-item"
      >
        <div class="registration-item__heading">
          {{ registration.start_location }} – {{ registration.end_location }}
        </div>

        <div>
          Afstand: {{ registration.distance }}<br>
          Begin- en eindstand: {{ registration.start_mileage }} – {{ registration.end_mileage }}<br>
          Datum: {{ registration.date }}
        </div>
      </router-link>
    </virtual-list>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { parseISO } from 'date-fns';
import VirtualList from 'vue-virtual-scroll-list';
import format from '../../helpers/format';
import TitleBar from '../../components/TitleBar.vue';

export default {
  name: 'kilometer-registrations',

  components: {
    TitleBar,
    VirtualList,
  },

  data() {
    return {
      //
    };
  },

  computed: {
    ...mapGetters([
      'activeVehicle',
      'registrations',
    ]),

    registrationsList() {
      if (typeof this.registrations === 'undefined' || !this.registrations) {
        return null;
      }

      return this.registrations.map((registration) => {
        const registrationClone = { ...registration };

        registrationClone.date = format(parseISO(registration.created_at), 'dd MMMM yyyy');

        return registrationClone;
      });
    },
  },

  methods: {
    ...mapActions([
      'fetchKilometerRegistrations',
      'fetchUser',
    ]),

    refreshKilometerRegistrations() {
      this.fetchKilometerRegistrations();
      this.fetchUser();
    },
  },

  watch: {
    $route: 'refreshKilometerRegistrations',
  },

  mounted() {
    this.fetchKilometerRegistrations();
    this.fetchUser();
  },
};
</script>

<style scoped>
.registrations__meta {
  padding: 1rem;
  border-bottom: 1px solid #eee;
  height: 3.25rem;
  font-size: .875rem;
  line-height: 1.428; /* 1.25rem; */
}

.registrations__list {
  max-height: calc(100% - 48px - 3.25rem);
}

.registration-item {
  flex-direction: column;
  align-items: flex-start;
}

.registration-item__heading {
  font-weight: 700;
  margin-bottom: .5rem;
}
</style>
