import { render, staticRenderFns } from "./QuizQuestion.vue?vue&type=template&id=431820ed&scoped=true&"
import script from "./QuizQuestion.vue?vue&type=script&lang=js&"
export * from "./QuizQuestion.vue?vue&type=script&lang=js&"
import style0 from "./QuizQuestion.vue?vue&type=style&index=0&id=431820ed&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "431820ed",
  null
  
)

export default component.exports