<template>
  <div class="vca-toolbox h-100">
    <title-bar
      :left="{ text: 'Toolboxen', path: '/vca-toolbox', icon: 'chevron-left' }"
      title="Toolbox"
    />

    <div v-if="vcaToolboxesLoadingState === 'loading'">
      Een moment geduld...
    </div>

    <div class="content-section" v-else>
      <quiz
        :toolbox="activeVcaToolbox"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import TitleBar from '../../components/TitleBar.vue';
import Quiz from './Quiz.vue';

export default {
  name: 'vca-toolbox',

  components: {
    Quiz,
    TitleBar,
  },

  created() {
    this.$store.commit('setActiveVcaToolboxId', this.$route.params.id);
  },

  computed: {
    ...mapGetters([
      'vcaToolboxes',
      'activeVcaToolbox',
      'user',
      'vcaToolboxStoreLoadingState',
      'vcaToolboxesLoadingState',
    ]),
  },

  methods: {
    ...mapActions([
      'fetchVcaToolboxes',
      'fetchUser',
      'storeVcaToolbox',
    ]),

    refreshEntries() {
      this.fetchVcaToolboxes();
      this.fetchUser();
    },
  },

  watch: {
    $route: 'refreshEntries',
  },

  mounted() {
    this.refreshEntries();
  },
};
</script>
