<template>
  <div class="tests h-100">
    <title-bar
      :leftbutton="{ text: 'Ververs', action: refreshEntries, icon: 'sync' }"
      title="Toolbox"
    />

    <div class="content-section">
      <div class="tests__empty-state" v-if="vcaToolboxesLoadingState === 'loading'">
        Een moment geduld...
      </div>

      <ul class="tests__list" v-else-if="vcaToolboxesList.length">
        <li
          class="tests__item"
          v-for="vcaToolbox in vcaToolboxesList"
          :key="vcaToolbox.id"
        >
          <router-link
            class="tests__item-link"
            :to="{ name: 'vca-toolbox-view', params: { id: vcaToolbox.id } }"
          >
            <h2 class="tests__item-heading">
              {{ vcaToolbox.title }}
            </h2>

            <div v-if="vcaToolbox.completed">
              Afgerond
            </div>

            <div v-else-if="!vcaToolbox.is_online">
              Verlopen
            </div>

            <div class="tests__item-duration" v-else>
              <div class="tests__from">
                <strong>Beschikbaar vanaf</strong><br>
                {{ vcaToolbox.formattedStartsAt }}
              </div>

              <div class="tests__to">
                <strong>Beschikbaar tot</strong><br>
                {{ vcaToolbox.formattedExpiresAt }}
              </div>
            </div>
          </router-link>
        </li>
      </ul>

      <div class="tests__empty-state" v-else>
        Op het moment zijn er geen tests.
      </div>
    </div>
  </div>
</template>

<script>
import parseISO from 'date-fns/parseISO';
import { mapActions, mapGetters } from 'vuex';
import TitleBar from '../../components/TitleBar.vue';
import format from '../../helpers/format';

export default {
  name: 'vca-toolbox',

  components: {
    TitleBar,
  },

  data() {
    return {
      //
    };
  },

  computed: {
    ...mapGetters([
      'vcaToolboxes',
      'vcaToolboxesLoadingState',
    ]),

    vcaToolboxesList() {
      return this.vcaToolboxes.map((timesheet) => {
        const clone = { ...timesheet };

        clone.formattedStartsAt = format(parseISO(clone.starts_at), 'EEEE d MMMM yyyy');
        clone.formattedExpiresAt = format(parseISO(clone.expires_at), 'EEEE d MMMM yyyy');

        return clone;
      });
    },
  },

  methods: {
    ...mapActions([
      'fetchVcaToolboxes',
      'fetchUser',
    ]),

    refreshEntries() {
      this.fetchVcaToolboxes();
      this.fetchUser();
    },
  },

  watch: {
    $route: 'refreshEntries',
  },

  mounted() {
    this.refreshEntries();
  },
};
</script>

<style scoped>
.tests .content-section {
  max-height: calc(100% - 48px - 49px);
}

.tests__list {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.tests__item {
  padding: 1rem 1.125rem;
  border-bottom: 1px solid #e3e3e3;
  border-top: 1px solid transparent;
  line-height: 1.4;
  display: flex;
  align-items: center;
}

.tests__item-link {
  color: var(--text-color);
  text-decoration: none;
}

.tests__from,
.tests__to {
  min-width: 110px;
  padding-right: 2rem;
}

.tests__item-heading {
  margin: 0 0 .75rem;
  font-size: 1.0625rem;
}

.tests__item-duration {
  display: flex;
  align-items: center;
}

.tests__ellipsis {
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

.tests__empty-state {
  padding: 3rem 1rem;
  text-align: center;
  font-size: 1.125rem;
  max-width: 350px;
  margin: 0 auto;
}

@media (min-width: 1024px) {
  .tests__from,
  .tests__to {
    width: 155px;
  }
}

@media (min-width: 1200px) {
  .tests__from,
  .tests__to {
    width: 210px;
  }
}
</style>
