<template>
  <div class="h-100">
    <title-bar back="/" title="Veiligheidsmelding doen"></title-bar>

    <div class="content-section">
      <form
        action="#"
        method="POST"
        @submit="onSubmit"
        ref="contactForm"
        enctype="multipart/form-data"
      >
        <div class="form-group">
          <label for="subject">Onderwerp</label>
          <input
            class="form-control"
            type="text"
            name="subject"
            id="subject"
            value=""
            required
          >
        </div>

        <div class="form-group">
          <label for="leader">Uitvoerder</label>
          <select class="select-control" name="leader" id="leader">
            <option value="">Onbekend</option>

            <option v-for="contact in contacts" :value="contact.id" :key="contact.id">
              {{ contact.name }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <label for="date">Project datum</label>
          <input class="form-control" type="date" name="date" id="date" :value="defaultDate">
        </div>

        <div class="form-group">
          <label for="message">Bericht</label>
          <textarea
            class="form-control"
            type="text"
            name="message"
            id="message"
            rows="4"
          ></textarea>
        </div>

        <div class="form-group">
          <label for="images">Afbeeldingen</label>
          <input class="form-control" type="file" id="images" multiple @change="onImagesChange">
        </div>

        <div v-if="isLoggedIn">
          <input class="form-control" type="hidden" name="user_id" :value="jwtSubject">
        </div>

        <div class="alert alert--danger" v-if="formState === 'error'">
          Er ging iets mis bij het opslaan. Probeer het later opnieuw.
        </div>

        <div class="form-group">
          <button
            class="btn btn--primary btn--full"
            type="submit"
            :disabled="formState === 'loading'"
          >
            {{ formState === 'loading' ? 'Een moment geduld...' : 'Versturen' }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
/* global gtag */

import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { format } from 'date-fns';
import TitleBar from '../../components/TitleBar.vue';

export default {
  name: 'safety-contact',

  components: {
    TitleBar,
  },

  data() {
    return {
      formState: 'idle',
      images: [],
    };
  },

  computed: {
    ...mapGetters([
      'isLoggedIn',
      'jwtSubject',
      'contacts',
    ]),

    defaultDate() {
      return format(new Date(), 'yyyy-MM-dd');
    },
  },

  methods: {
    ...mapActions([
      'postSafetyContact',
    ]),

    onImagesChange(e) {
      const files = e.target.files || e.dataTransfer.files;

      if (!files.length) {
        return;
      }

      Vue.set(this, 'images', files);
    },

    onSubmit(e) {
      e.preventDefault();

      const { contactForm } = this.$refs;
      const data = new FormData(contactForm);

      Vue.set(this, 'formState', 'loading');

      for (let i = 0; i < this.images.length; i += 1) {
        const image = this.images.item(i);
        data.append(`images[${i}]`, image, image.name);
      }

      this.postSafetyContact(data)
        .then(() => {
          Vue.set(this, 'formState', 'success');
          this.$toasted.show('Melding is verzonden', { type: 'success' }).goAway(2000);
          this.$router.push({ name: 'dashboard' });

          if (typeof gtag !== 'undefined') {
            gtag('event', 'submit', {
              event_category: 'Safety contact',
              event_label: '',
            });
          }
        })
        .catch(() => {
          Vue.set(this, 'formState', 'error');
        });
    },
  },
};
</script>
