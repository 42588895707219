import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faTachometerAlt,
  faNewspaper,
  faUsers,
  faCog,
  faCalendar,
  faExclamationTriangle,
  faCar,
  faPlane,
  faCheck,
  faTimes,
  faClock,
  faHardHat,
  faChevronLeft,
  faChevronRight,
  faUserClock,
  faSpinner,
  faSync,
  faPlus,
  faListUl,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faTachometerAlt);
library.add(faNewspaper);
library.add(faUsers);
library.add(faCog);
library.add(faCalendar);
library.add(faExclamationTriangle);
library.add(faCar);
library.add(faPlane);
library.add(faCheck);
library.add(faTimes);
library.add(faClock);
library.add(faHardHat);
library.add(faChevronLeft);
library.add(faChevronRight);
library.add(faUserClock);
library.add(faSpinner);
library.add(faSync);
library.add(faPlus);
library.add(faListUl);

Vue.component('font-awesome-icon', FontAwesomeIcon);
