<template>
  <div class="contacts">
    <title-bar title="Contacten" />

    <virtual-list class="contacts__list" :size="56" :remain="25">
      <router-link
        v-for="contact in contacts"
        :key="contact.id"
        :to="`/contacts/${contact.id}`"
        class="contact-item list-item"
      >
        <div class="contacts__image">
          <img
            :src="contact.image ? contact.image : 'img/user-placeholder.jpg'"
            alt=""
            width="80"
            height="80"
            loading="lazy"
          >
        </div>

        {{ contact.name }}
      </router-link>
    </virtual-list>
  </div>
</template>

<script>
// import Vue from 'vue';
import virtualList from 'vue-virtual-scroll-list';
import { mapActions, mapGetters } from 'vuex';
import TitleBar from '../../components/TitleBar.vue';

export default {
  name: 'contacts',

  components: {
    virtualList,
    TitleBar,
  },

  data() {
    return {
    };
  },

  computed: {
    ...mapGetters([
      'contacts',
    ]),
  },

  methods: {
    ...mapActions([
      'fetchContacts',
    ]),
  },

  mounted() {
    this.fetchContacts();
  },
};
</script>

<style scoped>
.contact-item {
  height: 56px;
  font-size: .875rem;
  line-height: calc(48px - 1.5rem);
}

.contacts__list {
  position: absolute;
  top: 48px;
  left: 0;
  width: 100%;
  max-height: calc(100% - 48px);
  user-select: none;
}

.contacts__image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 .75rem 0 -.5rem;
  overflow: hidden;
  position: relative;
}

.contacts__image img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  display: block;
  transform: translate(-50%, -50%);
  object-fit: cover;
}
</style>
