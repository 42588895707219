function formatForWhatsApp(number) {
  let formattedNumber = number;

  if (formattedNumber.indexOf('0') === 0) {
    formattedNumber = formattedNumber.replace(/^0/, '', formattedNumber);
    formattedNumber = `31${formattedNumber}`;
  }

  return formattedNumber;
}

export default (number, forWhatsApp = false, countryCode = '+31') => {
  let formattedNumber = number;

  // Remove country code
  formattedNumber = formattedNumber.replace(/^\+31/, '');

  formattedNumber = formattedNumber
    .replace('-', '')
    .replace(' ', '')
    .replace('(', '')
    .replace(')', '');

  if (forWhatsApp) {
    return formatForWhatsApp(formattedNumber);
  }

  // Remove initial zeroes (for numbers without country code)
  formattedNumber = formattedNumber.replace(/^0/, '');

  return `${countryCode}${formattedNumber}`;
};
