import { format } from 'date-fns';

import nl from 'date-fns/locale/nl';

const locales = {
  nl,
};

export default (date, formatStr) => format(date, formatStr, {
  locale: locales[window.localeId],
});
