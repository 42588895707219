<template>
  <div class="planning-detail">
    <title-bar
      :left="{ path: '/planning', text: 'Planning', icon: 'chevron-left' }"
      :title="titleDate"
    />

    <div class="planning-detail__day-switch">
      <button type="button" @click="onPrevDayClick">
        &laquo; Vorige dag
      </button>

      <button type="button" @click="onNextDayClick">
        Volgende dag &raquo;
      </button>
    </div>

    <div class="content-section">
      <ul class="planning-detail__list" v-if="items">
        <li
          :class="{
            'planning-detail__list-item': true,
            'is-concept': item.is_concept,
          }"
          v-for="item in items"
          :key="item.id"
        >
          <button
            type="button"
            class="planning-detail__title"
            @click="(e) => openModal(e, item.id)"
          >
            <span v-if="item.is_concept">Concept -</span>
            {{ item.title }}
          </button>

          <div class="planning-detail__date">
            <span>Van:</span> {{ item.startFormatted }}
          </div>
          <div class="planning-detail__date">
            <span>Tot:</span> {{ item.endFormatted }}
          </div>

          <div class="planning-detail__crew">
            <router-link
              class="planning-detail__employee"
              v-for="employee in item.crew"
              :to="`/contacts/${employee.id}`"
              :key="employee.id"
            >
              <img
                class="planning-detail__employee-avatar"
                :src="employee.avatar ? employee.avatar : '/img/user-placeholder.png'"
                alt=""
                aria-hidden="true"
              >
              {{ employee.fullname }}
            </router-link>
          </div>

          <planning-modal :modal-key="`modal-${item.id}`" :planning-item="item" />
        </li>
      </ul>

      <div class="planning-detail__absences" v-if="dayPlanning && dayPlanning.on_leave">
        <div class="planning-detail__absences-label">
          Absent/ziek/vakantie
        </div>

        <router-link
          class="planning-detail__employee"
          v-for="employee in dayPlanning.on_leave"
          :to="`/contacts/${employee.id}`"
          :key="employee.id"
        >
          <img
            class="planning-detail__employee-avatar"
            :src="employee.avatar ? employee.avatar : '/img/user-placeholder.png'"
            alt=""
            aria-hidden="true"
          >
          {{ employee.fullname }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
// import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import addDays from 'date-fns/addDays';
import parseISO from 'date-fns/parseISO';
import format from '../../helpers/format';
import TitleBar from '../../components/TitleBar.vue';
import PlanningModal from '../../components/PlanningModal.vue';
import filterPlanningItems from '../../helpers/FilterPlanningItems';
import transformPlanningItems from '../../helpers/TransformPlanningItems';

export default {
  name: 'planning-detail',

  components: {
    PlanningModal,
    TitleBar,
  },

  data() {
    return {
      date: null,
    };
  },

  computed: {
    ...mapGetters([
      'dayPlanning',
      'planningFilters',
    ]),

    items() {
      if (!this.dayPlanning) {
        return null;
      }

      const { items } = this.dayPlanning;

      const transformed = transformPlanningItems(filterPlanningItems(items, this.planningFilters));

      return transformed;
    },

    titleDate() {
      if (!this.date) {
        return 'Dag';
      }

      return format(this.date, 'EEEE dd-MM-yyyy');
    },
  },

  methods: {
    ...mapActions([
      'fetchDayPlanning',
      'resetDayPlanning',
    ]),

    openModal(event, id) {
      this.$modal.show(`modal-${id}`);
    },

    onPrevDayClick() {
      this.routeToDate(this.getDateForIncrement(-1));
    },

    onNextDayClick() {
      this.routeToDate(this.getDateForIncrement(1));
    },

    routeToDate(date) {
      this.$router.push({
        name: 'planning-detail',
        params: { date },
      });
    },

    getDateForIncrement(increment) {
      return format(addDays(this.date, increment), 'yyyy-MM-dd');
    },
  },

  mounted() {
    const { params } = this.$route;
    this.date = parseISO(params.date);
    this.fetchDayPlanning(params.date);
  },

  beforeRouteUpdate(to, from, next) {
    const { params } = to;
    this.date = parseISO(params.date);
    this.fetchDayPlanning(params.date);
    next();
  },

  destroyed() {
    this.resetDayPlanning();
  },
};
</script>

<style>
.planning-detail {
  height: 100%;
}

.planning-detail__list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.planning-detail__list-item {
  padding: .875rem;
  border-bottom: 1px solid #ddd;
}

.planning-detail__list-item.is-concept {
  padding-left: 1.5rem;
  position: relative;
  opacity: .4;
}

.planning-detail__list-item.is-concept:before {
  content: '';
  width: .5rem;
  height: 100%;
  background-image: repeating-linear-gradient(
    45deg,
    #ddd,
    #ddd 10px,
    #aaa 10px,
    #aaa 20px
  );
  background-repeat: repeat;
  position: absolute;
  top: 0;
  left: 0;
}

.planning-detail__title {
  font-size: 1rem;
  display: block;
  margin-bottom: .5rem;
  padding: 0;
  font-weight: 700;
  border: 0;
  background: transparent;
  text-align: left;
  line-height: 1.4;
}

.planning-detail__date {
  margin-bottom: .25rem;
}

.planning-detail__date span {
  color: #aaa;
  display: inline-block;
  min-width: 40px;
}

.planning-detail__crew {
  padding-top: .5rem;
}

.planning-detail__employee {
  display: inline-block;
  vertical-align: top;
  margin: 0 .3rem .375rem -.05rem;
  background-color: #eee;
  border-radius: 50px;
  padding: .375rem .75rem .375rem .375rem;
  color: #333;
  text-decoration: none;
}

.planning-detail__employee-avatar {
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: .25rem;
}

.planning-detail__absences {
  padding: .875rem;
}

.planning-detail__absences-label {
  color: #aaa;
  margin-bottom: .5rem;
}

.planning-detail__day-switch {
  background-color: var(--primary-color-shade);
  display: flex;
}

.planning-detail__day-switch button {
  border: 0;
  background-color: transparent;
  flex: 1 1 auto;
  color: #fff;
  font-size: .875rem;
  padding: .875rem .75rem;
  appearance: none;
}

.planning-detail .content-section {
  height: calc(100% - 48px - 45px);
}
</style>
