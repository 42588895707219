<template>
  <div>
    <h2 class="quiz__card-title" v-if="slide.title">
      {{ slide.title }}
    </h2>

    <div class="quiz__card-media" v-if="hasMedia">
      <img
        v-if="mediaFileType === 'image'"
        class="quiz__card-media-image"
        :src="`${mediaHost}${slide.mediaItem.path}`"
        alt=""
      />
      <a
        v-if="mediaFileType === 'file'"
        :href="`${mediaHost}${slide.mediaItem.path}`"
        class="btn btn--primary text-decoration-none"
        target="_blank"
      >
        Bekijk {{ slide.mediaItem.original_filename
          ? slide.mediaItem.original_filename.replace(/_|\-/, ' ')
          : slide.mediaItem.path }}
      </a>
    </div>

    <div
      class="quiz__card-media quiz__card-media--video"
      v-if="videoEmbed"
      v-html="isActive ? videoEmbed : ''"
    />

    <div
      class="quiz__card-content"
      v-if="slide.content"
      v-html="slide.content"
    />

    <a
      class="btn btn--primary text-decoration-none mb-3"
      v-if="slide.button_url && slide.button_text"
      target="_blank"
      rel="nofollow noopener"
      :href="slide.button_url"
    >
      {{ slide.button_text }}
    </a>
  </div>
</template>

<script>
import config from '../../config';
import videoHelpers from '../../helpers/Video';

export default {
  props: {
    slide: Object,
    isActive: Boolean,
  },

  data() {
    return {
      mediaHost: config.mediaBasePath,
    };
  },

  computed: {
    videoEmbed() {
      if (!this.slide.video_url) {
        return null;
      }

      return videoHelpers.videoEmbed(this.slide.video_url);
    },

    hasMedia() {
      return this.slide.mediaItem && this.slide.mediaItem.path;
    },

    mediaFileType() {
      if (!this.hasMedia) {
        return false;
      }

      return this.slide.mediaItem.type;
    },
  },
};
</script>

<style scoped>
.quiz__card-title {
  margin-bottom: 15px;
}

.quiz__card-content {
  margin-bottom: 15px;
}

.quiz__card-content >>> > * {
  margin-top: 0;
  margin-bottom: 1rem;
}

.quiz__card-content >>> a:not(.btn) {
  color: currentColor;
}
</style>
