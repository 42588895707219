import ApiRequest from '../helpers/ApiRequest';
import transformPlanningForCalendar from '../helpers/TransformPlanningForCalendar';
import filterPlanningItems from '../helpers/FilterPlanningItems';
import isTimestampExpired from '../helpers/IsTimestampExpired';

const PlanningStore = {
  state: {
    planning: null,
    planningFetchDate: null,
    dayPlanning: null,
    calendarState: null,
    filter: {
      employeeId: null,
      search: null,
    },
  },

  getters: {
    planning(state) {
      let items = state.planning;

      if (state.filter) {
        if (state.filter.employeeId || state.filter.search) {
          items = filterPlanningItems(state.planning, state.filter);
        }
      }

      return transformPlanningForCalendar(items);
    },

    dayPlanning(state) {
      return state.dayPlanning;
    },

    planningFilters(state) {
      return state.filter;
    },

    calendarState(state) {
      return state.calendarState;
    },
  },

  mutations: {
    setPlanning(state, planning) {
      state.planning = planning;
    },

    setDayPlanning(state, dayPlanning) {
      state.dayPlanning = dayPlanning;
    },

    setPlanningFilter(state, filters) {
      state.filter = filters;
    },

    setPlanningFetchDate(state, timestamp) {
      state.planningFetchDate = timestamp;
    },

    setCalendarState(state, information) {
      state.calendarState = information;
    },
  },

  actions: {
    async fetchPlanning({ commit, state, rootState }, forceRefresh = false) {
      const now = new Date().getTime();
      const cacheTime = 30 * 1000; // 30 seconds

      // If there's a planning and its inside the cache timeframe
      if (
        !forceRefresh
        && state.planning
        && state.planningFetchDate
        && !isTimestampExpired(state.planningFetchDate, cacheTime)
      ) {
        return;
      }

      const res = await ApiRequest.authorizedGet('/planning/list', rootState.user.currentJWT);

      commit('setPlanning', res.data);
      commit('setPlanningFetchDate', now);
    },

    async fetchDayPlanning({ commit, rootState }, date) {
      const res = await ApiRequest.authorizedGet(`/planning/day/${date}`, rootState.user.currentJWT);

      commit('setDayPlanning', res.data);
    },

    resetDayPlanning({ commit }) {
      commit('setDayPlanning', null);
    },

    applyPlanningFilter({ commit }, filters) {
      commit('setPlanningFilter', filters);
    },

    resetPlanningFilter({ commit }) {
      commit('setPlanningFilter', null, null);
    },

    setCalendarState({ commit }, information) {
      commit('setCalendarState', information);
    },
  },
};

export default PlanningStore;
