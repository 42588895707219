<template>
  <tab-bar :tabs="tabs" />
</template>

<script>
import TabBar from '../../components/TabBar.vue';

export default {
  components: {
    TabBar,
  },

  data() {
    return {
      tabs: [
        { title: 'Nieuw', path: '/time-entry', icon: 'plus' },
        { title: 'Overzicht', path: '/time-entries', icon: 'list-ul' },
        { title: 'Goedkeuren', path: '/time-entry-approval', icon: 'check' },
      ],
    };
  },
};
</script>
