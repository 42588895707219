import axios from 'axios';
import config from '../config';

const baseHeaders = {
  'X-Requested-With': 'XMLHttpRequest',
  'Content-Type': 'application/json',
};

export default {
  get(path, extraHeaders = {}) {
    return axios.get(`${config.apiUrl}${path}`, {
      headers: {
        ...baseHeaders,
        ...extraHeaders,
      },
    });
  },

  post(path, values, extraHeaders = {}) {
    return axios.post(`${config.apiUrl}${path}`, values, {
      headers: {
        ...baseHeaders,
        ...extraHeaders,
      },
    });
  },

  authorizedGet(path, jwt, extraHeaders = {}) {
    return this.get(path, {
      Authorization: `Bearer ${jwt}`,
      ...extraHeaders,
    });
  },

  authorizedPost(path, values, jwt, extraHeaders = {}) {
    return this.post(path, values, {
      Authorization: `Bearer ${jwt}`,
      ...extraHeaders,
    });
  },
};
