import ApiRequest from '../helpers/ApiRequest';

const SafetyContactModule = {
  state: {
    currentJWT: '',
  },

  getters: {
    //
  },

  mutations: {
    //
  },

  actions: {
    async postSafetyContact(store, values) {
      await ApiRequest.post('/safety-contact', values, {
        'Content-Type': 'multipart/form-data',
      });
    },
  },
};

export default SafetyContactModule;
